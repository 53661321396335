import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { Container } from "../../../../Components/Layout/Container/Container"
import { 
    TableContainer,
    TableHeader,
    TableHeaderItem,
    TableBody,
    TableRow,
    TableItem
 } from "../../../../Components/DataDisplay/Table/Table"

import { Input } from "../../../../Components/Input/TextField/Input"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"
import { usePositionData } from "../../../../Utils/Contexts/PositionDataContext"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { useEffect, useState } from "react"

export const PosVerpackung = () => {

    //Get Data
    const {addVerpackungPos, PosVerpackung, activePosition} = usePositionData();
    const {atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const[newPosVerpackung, setNewPosVerpackung] = useState({
        art: '',
        referenznummer: '',
        bewilligungsinhaber: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPosVerpackung((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewPosVerpackung((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddVerpackung = (e) =>{
        e.preventDefault();
        addVerpackungPos(activePosition.warePositionsnummer, newPosVerpackung);

        console.log(activePosition.warePositionsnummer, newPosVerpackung);
        setNewPosVerpackung({ 
            artderVerpackung: "",
            anzahlderPackstucke: "",
            versandzeichen: "",
            packstuckverweisPositionsnummer: ""
        });
    }


    return(
        <>
        <FormWrapper>
            <ColumnGrid>
                <FormBox header="Verpackung" type="center">
                    <Container size="small">
                        <Input
                            label="Anzahl der Packstücke"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="anzahlderPackstucke"
                            value={PosVerpackung.anzahlderPackstucke}
                        />
                        <DropDownButton
                            name='Art der Verpackung'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '120')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('artderVerpackung', item)}
                            dropname='artderVerpackung'
                        />
                        <Input
                            label="Verweis / Positionsnummer"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="packstuckverweisPositionsnummer"
                            value={PosVerpackung.packstuckverweisPositionsnummer}
                        />
                        <Input
                            label="Versandkennzeichen"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="versandzeichen"
                            value={PosVerpackung.versandzeichen}
                        />
                        <ButtonGroup>
                            <Button onClick={handleAddVerpackung}>Hinzufügen</Button>
                        </ButtonGroup>
                    </Container>
                </FormBox>

                <TableContainer>
                        <TableHeader>
                            <TableHeaderItem>
                                Nr.
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Anzahl der Packstücke
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Art der Verpackung
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Verweis / Positionsnummer
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Versandzeichen
                            </TableHeaderItem>
                        </TableHeader>
                        {/* Table Body */}
                        {activePosition?.verpackung.map((item, index) => (
                            <>
                                <TableRow>
                                    <TableItem>
                                        {index + 1}
                                    </TableItem>
                                    <TableItem>
                                        {item.anzahlderPackstucke}
                                    </TableItem>
                                    <TableItem>
                                        {item.artderVerpackung}
                                    </TableItem>
                                    <TableItem>
                                        {item.packstuckverweisPositionsnummer}
                                    </TableItem>
                                    <TableItem>
                                        Löschen
                                    </TableItem>
                                </TableRow>
                            </>))}
                        <TableBody>
                        </TableBody>
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
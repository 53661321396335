import axios from "axios";
import Base from '../Base.json';
export const CreateAtlasMessage = async (
    atlasPositionData, 
    headerData, 
    bewilligung, 
    suplyActors, 
    befoerderungsMittelAnDerGrenze, 
    zusätzlicheInformationen, 
    unterlageHeader,
    warenOrt,
    reybexData,
    wiederEinfuhrLand,
    sonstigerVerweis,
    onStatusUpdate
) => {


    console.log("Atlas Position:", atlasPositionData)
    console.log(headerData) 


    const SendAtlasData = {

            dataIdentifier: "EXPDAT",
            kundenNumber: "",
            mandantId: "FTAM",
            module: {
                ausfuhr: {
                    data: true,
                    email: "",
                    autosend: false
                }
            },
            messageData: {
                ausfuhr: {
                    expdat: [
                            {
                                kopf: {
                                    eoriNiederlassungsnummer: headerData.eoriNiederlassungsnummer || "",
                                    dienststellennummer: headerData.dienststellennummer || "",
                                    lrn: headerData.lrn || "",
                                    artderAnmeldung: headerData.artderAnmeldung || "",
                                    artderAusfuhranmeldung: headerData.artderAusfuhranmeldung || "",
                                    beteiligtenKonstellation: headerData.beteiligtenKonstellation || "",
                                    zeitpunktderAnmeldung: headerData.zeitpunktderAnmeldung || "",
                                    massgeblichesDatum: headerData.massgeblichesDatum || "",
                                    kopfDatumdesAusgangs: headerData.kopfDatumdesAusgangs || "",
                                    zeitpunktDerGestellung: headerData.zeitpunktDerGestellung || "",
                                    zeitpunktdesEndesderLadetatigkeit: headerData.zeitpunktdesEndesderLadetatigkeit || "",
                                    sicherheit: headerData.sicherheit || "",
                                    besondereUmstande: headerData.besondereUmstande || "",
                                    inRechnunggestellterGesamtbetrag: headerData.inRechnunggestellterGesamtbetrag || "",
                                    rechnungswahrung: headerData.rechnungswahrung || "",
                                    bewilligung: bewilligung?.bewilligung.map((item, index) => (
                                        {
                                            art: item.art || "", 
                                            referenznummer: item.referenznummer || "",
                                        }
                                    )),
                                    gestellungszollstelle: headerData.gestellungszollstelle || "",
                                    ausfuhrzollstelleDienststellennummer: headerData.ausfuhrzollstelleDienststellennummer || "",
                                    ausfuhrZollstelleFurDieErganzende: headerData.ausfuhrZollstelleFurDieErganzende || "",
                                    vorgeseheneAusgangszollstelleDienststellennummer: headerData.vorgeseheneAusgangszollstelleDienststellennummer || "",
                                    tatsachlicheAusgangszollstelleDienststellennummer: headerData.tatsachlicheAusgangszollstelleDienststellennummer,
                                    "aussenwirtschaftsrechtlicherAusfuhrer": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    "ausfuhrer": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    "anmelder": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": "",
                                        "phone": "",
                                        "ansprechName": "",
                                        "ansprechEmail": ""
                                    },
                                    "vertreter": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "phone": "",
                                        "ansprechName": "",
                                        "ansprechEmail": ""
                                    },
                                    "subunternehmer": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    artdesGeschafts: headerData.artdesGeschafts || "",
                                    ausfuhrland: headerData.ausfuhrland || "",
                                    bestimmungsLand: headerData.bestimmungsLand || "",
                                    lieferkettenBeteiligter: suplyActors, 
                                    lieferbedingungIncotermCode: headerData.lieferbedingungIncotermCode || "",
                                    lieferbedingungUnLocode: headerData.lieferbedingungUnLocode || "",
                                    lieferbedingungOrt: headerData.lieferbedingungOrt || "",
                                    lieferbedingungLand: headerData.lieferbedingungLand || "",  
                                    lieferbedingungText: headerData.lieferbedingungText || "",
                                    wiedereinfuhr: wiederEinfuhrLand,
                                    "namlichkeitsmittel": [
                                        {
                                            "namlichkeitsmitteArt": "",
                                            "namlichkeitsmittelTextlicheBeschreibung": ""
                                        }
                                    ],
                                    "erzeugnis": [
                                        {
                                            "erzeugnisWarenbezeichnung": "",
                                            "unterpositionDesHarmonisiertenSystems": "",
                                            "unterpositionDerKombiniertenNomenklatur": ""
                                        }
                                    ],
                                    "vorpapier": [
                                        {
                                            "art": "",
                                            "qualifikator": "",
                                            "referenznummer": ""
                                        }
                                    ],
                                    unterlage: unterlageHeader,
                                    sonstigerVerweis: sonstigerVerweis,
                                    zusatzlicherInformation: zusätzlicheInformationen,
                                    containerIndikator: headerData.containerIndikator || "",
                                    inlandischerVerkehrszweig: headerData.inlandischerVerkehrszweig || "",
                                    verkehrszweigAnDerGrenze: headerData.verkehrszweigAnDerGrenze || "",
                                    gesamtRohmasse: headerData.gesamtRohmasse || "",
                                    referenznummerUCR: headerData.referenznummerUCR || "",
                                    registriernummerextern: headerData.registriernummerextern || "",
                                    "beforderer": {
                                        "tin": "",
                                        "niederlassungsNummer": ""
                                    },
                                    "versender": {
                                        "tin": "",
                                        "niederlassungsNummer": "",
                                        "name": "",
                                        "strasse": "",
                                        "plz": "",
                                        "ort": "",
                                        "land": ""
                                    },
                                    "empfanger": {
                                        "tin": headerData.empfanger.tin || "",
                                        "niederlassungsNummer": headerData.empfanger.niederlassungsNummer || "",
                                        "name": headerData.empfanger.name || "",
                                        "strasse": headerData.empfanger.strasse || "",
                                        "plz": headerData.empfanger.plz || "",
                                        "ort": headerData.empfanger.ort || "",
                                        "land": headerData.empfanger.land || ""
                                    },
                                    "transportausrustung": [
                                        {
                                            "containernummer": "",
                                            "anzahlderVerschlusse": "",
                                            "verschluss": [
                                                {
                                                    "verschlusskennzeichen": ""
                                                }
                                            ],
                                            "warenpositionsverweis": [
                                                {
                                                    "positionsnummer": ""
                                                }
                                            ]
                                        }
                                    ],
                                    warenortArtdesOrtes: headerData.warenortArtdesOrtes || "",
                                    warenortArtDerOrtsbestimmung: headerData.warenortArtDerOrtsbestimmung || "",
                                    warenortBewilligungsnummer: headerData.warenortBewilligungsnummer || "",
                                    warenortZusatzlicheKennung: headerData.warenortZusatzlicheKennung || "",
                                    warenortUnLocode: headerData.warenortUnLocode || "",
                                    warenortGnssBreite: headerData.warenortGnssBreite || "",
                                    warenortGnssLang: headerData.warenortGnssLang || "",
                                    "warenort": {
                                        "name": warenOrt.name || "",
                                        "strasse": warenOrt.strasse || "",
                                        "plz": warenOrt.plz || "",
                                        "ort": warenOrt.ort || "",
                                        "land": warenOrt.land || "",
                                        "phone": warenOrt.phone || "",
                                        "ansprechName": warenOrt.ansprechName || "",
                                        "ansprechEmail": warenOrt.ansprechEmail || ""
                                    },
                                    beforderungsmittelBeimAbgang: befoerderungsMittelAnDerGrenze?.beforderungsmittelBeimAbgang.map((item, index) => (
                                        {
                                            artderIdentifikation: item.artderIdentifikation || "", 
                                            staatszugehorigkeit: item.staatszugehorigkeit || "",
                                            kennzeichen: item.kennzeichen || "",
                                        }
                                    )),
                                    "beforderungsroute": {},
                                    beforderungsmittelderGrenzeArt: headerData.beforderungsmittelderGrenzeArt || "",
                                    beforderungsmittelderGrenzeKennzeichen: headerData.beforderungsmittelderGrenzeKennzeichen || "",
                                    beforderungsmittelderGrenzeStaatszugehorigkeit: headerData.beforderungsmittelderGrenzeStaatszugehorigkeit || "",
                                    "transportdokument": [
                                        {
                                            "art": "",
                                            "qualifikator": "",
                                            "referenznummer": ""
                                        }
                                    ],
                                    beforderungskostenZahlungsart: headerData.beforderungskostenZahlungsart || "",
                                },
                                //Hier haben wir die Positionen
                                position: atlasPositionData
                            }
                        ]
                    }
                }
    }


        //Sending Request:
        if (SendAtlasData) {
            const b_token = localStorage.getItem('b_token');
            const b_accesstoken = localStorage.getItem('b_accesstoken');
            try {
                // Atlas-Request
                const atlas = await axios.post(
                    `${Base.beo}PutData`,
                    JSON.stringify(SendAtlasData),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            bearerToken: b_accesstoken,
                            token: b_token,
                        },
                    }
                );
    
                if (atlas.status === 200) {
                    onStatusUpdate({
                        type: 'success',
                        message: 'Atlas Nachricht wurde erfolgreich übermittelt.',
                    });
                }
    
                // Reybex-Request
                const r_token = localStorage.getItem('r_token');
    
                const reybexDataWithTag = {
                    ...reybexData,
                    tags: [
                        ...(reybexData.tags || []),
                        { tag: 'Ausfuhr' },
                    ],
                };
    
    
                const res = await axios.post(
                    `${Base.reybex}salesHead`,
                    JSON.stringify(reybexDataWithTag),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${r_token}`,
                        },
                    }
                );
    
                if (res.status === 200) {
                    console.log("Reybex Response", res.data);
                    onStatusUpdate({
                        type: 'success',
                        message: 'Atlasdaten wurden erfolgreich gesendet.',
                    });
                } else {
                    onStatusUpdate({
                        type: 'error',
                        message: 'Fehler beim Senden der Reybex Daten.',
                    });
                }
            } catch (err) {
                console.error(err);
                onStatusUpdate({
                    type: 'error',
                    message: `Fehler bei der Verarbeitung: ${err.message}`,
                });
            }
        }

}
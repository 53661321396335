import './ContentBase.css';

export const ContentBase = ({children}) => {
    return(
        <>
        <div className="bap_content_base_wrapper_col2">
            {children}
        </div>
        </>
    )
}
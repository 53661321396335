export const getHighestZIndex = () => {
    const elements = document.getElementsByTagName('*');
    let highest = 0;
    for (let i = 0; i < elements.length; i++) {
        const zIndex = window.getComputedStyle(elements[i]).zIndex;
        if (!isNaN(zIndex) && zIndex !== 'auto') {
            highest = Math.max(highest, parseInt(zIndex, 10));
        }
    }
    return highest;
};
import './AtlasMessageGrid.css';

export const AtlasMessageGrid = ({children}) => {

    return(
        <>
            <div className="bap_atlas_message_grid_wrapper">
                {children}
            </div>
        </>
    )

};

export const AtlasMessageGridItem = ({children}) => {
    
    return(
        <>
            <div className="bap_atlas_message_grid_item">
                {children}
            </div>
        </>
    )
};
import { AtlasMessageGrid, AtlasMessageGridItem } from "../../../../Components/Layout/Grids/AtlasMessageGrid/AtlasMessageGrid";
import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper";
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox";
import { Input, TextArea} from "../../../../Components/Input/TextField/Input";
import { DropDownButton, FullWidtDropDown } from "../../../../Components/Input/DropDown/Dropdown";
import { useFormData } from "../../../../Utils/Contexts/FormDataContext";
import { usePositionData } from "../../../../Utils/Contexts/PositionDataContext";
import { useEffect, useState } from "react";
import { AccordionContainer, AccordionTab, AccordionDisplayText } from "../../../../Components/DataDisplay/AccordionTab/Accordion";
import { Container } from "../../../../Components/Layout/Container/Container";

export const PosHeader = () => {

    const { atlasData, headerData } = useFormData();
    const { updateSpecificPosition, atlasPositionData, setActivePosition, activePosition,wtnData } = usePositionData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);

    useEffect(() => {
        if (atlasData) {
            setAtlasCodeList(atlasData);
        }
    }, [atlasData]);


    useEffect(() => {
        const savedPosition = localStorage.getItem("activePosition");
        if (savedPosition) {
            setActivePosition(JSON.parse(savedPosition));
        }
    }, [setActivePosition]);

    useEffect(() => {
        const savedPositionNumber = localStorage.getItem("position_number");
        console.log(savedPositionNumber);
        if (savedPositionNumber) {
            const position = atlasPositionData.find(
                (pos) => pos.warePositionsnummer === parseInt(savedPositionNumber, 10)
            );
            if (position) {
                console.log("TEST!",position);
                setActivePosition(position);
            }
        } else if (atlasPositionData.length > 0) {
            // Falls keine gespeicherte Position vorhanden ist, wähle die erste Position
            setActivePosition(atlasPositionData[0]);
            localStorage.setItem("position_number", atlasPositionData[0].warePositionsnummer);
        }
    }, [atlasPositionData, setActivePosition]);
    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const position = JSON.parse(localStorage.getItem("position_number"));
        if (position) {
            updateSpecificPosition(position, { [name]: value });
        }
    };

    const handleSelectChange = (name, value) => {
        const position = JSON.parse(localStorage.getItem("position_number"));

        if(position){
            updateSpecificPosition(position, { [name]: value });
        }
    };


    //Position change
    const handlePositionChange = (warePositionsnummer) => {
        // Speichere die aktuelle Position im localStorage
        localStorage.setItem("position_number", warePositionsnummer);
    
        const newPosition = atlasPositionData.find(
            (item) => item.warePositionsnummer === warePositionsnummer
        );
        if (newPosition) {
            setActivePosition(newPosition);
        }
    };

    
    return(
        <>
        <FormWrapper>
            <AtlasMessageGrid>
                {activePosition ? (
                    <>
                    <AtlasMessageGridItem>
                        <FullWidtDropDown
                            name="Position auswählen..."
                            placeholder="Bitte auswählen..."
                            items={atlasPositionData.map((item) => ({
                                value: item.warePositionsnummer, // Eindeutige ID der Position
                                label: item.wareWarenbezeichnung, // Lesbarer Text für den Dropdown
                            }))}
                            dropname="position"
                            selectedValue={localStorage.getItem("position_number")} // Aktive Position wird angezeigt
                            onSelect={(item) => handlePositionChange(item.value)} // Beim Wechsel wird die neue Position gesetzt
                        />

                        <FormBox header="Allgemeine Informationen für die Ausfuhranmeldung">
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.warePositionsnummer} 
                                label='Positionsnummer'
                                name='warePositionsnummer'
                                isDisabled={true}
                            />
                            <DropDownButton
                                name='Warennummer'
                                placeholder='Bitte auswählen...'
                                items={wtnData}
                                onSelect={(item) => handleSelectChange('wareWarennummerKN8', item)}
                                dropname='warennummer'
                            />
                            <TextArea
                                onChange={handleInputChange}
                                value={activePosition.wareWarenbezeichnung} 
                                label='Warenbezeichnung'
                                name='wareWarenbezeichnung'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.wareEigenmasse} 
                                label='Eigenmasse (in Kg)'
                                name='wareEigenmasse'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.wareRohmasse} 
                                label='Rohmasse (in Kg)'
                                name='wareRohmasse'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.mengeinbesondererMabeinheit} 
                                label='Menge in besonderer Maßeinheit'
                                name='mengeinbesondererMabeinheit'
                                isDisabled={false}
                            />
                            <Input
                                onChange={handleInputChange}
                                value={activePosition.statistischerWert} 
                                label='Statistischer Wert'
                                name='statistischerWert'
                                isDisabled={false}
                            />
                            <DropDownButton
                                name='Urpsrungsland'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '128')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('ursprungsland', item)}
                                dropname='ursprungsland'
                            />
                            <DropDownButton
                                name='Ursprung Versenderregion'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '118')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('ursprungsVersendungsregion', item)}
                                dropname='ursprungsVersendungsregion'
                            />
                            <DropDownButton
                                name='Beförderungskosten (Zahlungsart)'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '137')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('beforderungskostenZahlungsart', item)}
                                dropname='beforderungskostenZahlungsart'
                            />
                        </FormBox>
                    </AtlasMessageGridItem>
                    <AtlasMessageGridItem>                
                        <FormBox header="Beteiligtenkonstelation" inlineHeader="Verfahren">
                            <DropDownButton
                                name='Beantragtes Verfahren'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '115')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('beantragtesVerfahren', item)}
                                dropname='beantragtesVerfahren'
                            />
                            <DropDownButton
                                name='Vorhergehendes Verfahren'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '116')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('vorhergehendesVerfahren', item)}
                                dropname='vorhergehendesVerfahren'
                            />
                            <DropDownButton
                                name='Zusätzliches Verfahren'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '136')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('zusatzlichesVerfahren', item)}
                                dropname='zusatzlichesVerfahren'
                            />
                        </FormBox>

                        <FormBox inlineHeader="Geschäftsvorgang">
                            <DropDownButton
                                name='Art des Geschäfts'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '105')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('artdesGeschafts', item)}
                                dropname='artdesGeschafts'
                            />
                        </FormBox>

                        {/* 
                        <FormBox inlineHeader="Passive Veredelung">
                            <DropDownButton
                                name='Art des Geschäfts'
                                placeholder='Bitte auswählen...'
                                items={atlasCodeList
                                    ? atlasCodeList.find(item => item.type === '115')?.options || []
                                    : []
                                }
                                onSelect={(item) => handleSelectChange('artdesGeschafts', item)}
                                dropname='artdesGeschafts'
                            />
                        </FormBox>
                        */}
                        <AccordionContainer>
                            <AccordionTab tabName='Empfänger'>
                                <Container size='largeCenter'>
                                    <AccordionDisplayText labelText='Name'>
                                        <span>
                                            {headerData.empfanger.name}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Straße u. Hausnummer'>
                                        <span>
                                            {headerData.empfanger.strasse}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Postleitzahl'>
                                        <span>
                                            {headerData.empfanger.plz}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Ort'>
                                        <span>
                                            {headerData.empfanger.ort}
                                        </span>
                                    </AccordionDisplayText>
                                    <AccordionDisplayText labelText='Land'>
                                        <span>
                                            {headerData.empfanger.land}
                                        </span>
                                    </AccordionDisplayText>
                                </Container>
                            </AccordionTab>


                            <AccordionTab tabName="Versender">
                                <span>In Arbeit</span>
                            </AccordionTab>
                        </AccordionContainer>
                    </AtlasMessageGridItem>
                    </>
                ) : "Nicht vorhanden..."}
            </AtlasMessageGrid>
        </FormWrapper>
        </>
    )
};
import './TopSectionLabels.css';


export const TopSectionLabels = ({children}) => {
    return(
        <>
        <div className="bap_top_section_wrapper_main">
            {children}
        </div>
        </>
    )
};


export const TopSectionLabel = ({children, active=false, goTo}) => {
    return(
        <>
        <div className={`bap_tob_section_tab_wrapper ${active ? 'bap_tab_top_section_active' : ''}`} onClick={goTo}>
            <span>{children}</span>
        </div>
        </>
    )
};

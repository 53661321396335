import './ColumnGrid.css';

export const ColumnGrid = ({children}) => {
    return(
        <>
        <div className="bap_column_grid_main_wrapepr">
            {children}
        </div>
        </>
    )
};
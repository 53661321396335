import axios from 'axios';
import React, {createContext, useState, useContext, useEffect } from 'react';
import Base from '../../Api/Base.json';

import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

const FormDataContext = createContext();

export const FormDataProvider = ({children}) => {


    //Data which we want to get
    const [reybexData, setReybexData] = useState();
    const [atlasData, setAtlasData] = useState();
    const [atlasAddressData, setAtlasAddressData] = useState();


    const[ls_number, setLsNumber] = useState({
        ls_number: "",
        ls_id: ""
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Get the Reybex data
      const GetReyBexHeaderData = async () => {
          setLoading(true);
          const r_token = localStorage.getItem('r_token');
          try {
              const reybex = await axios.get(`${Base.reybex}domains/salesHead?docNumber=${ls_number.ls_number}`, {
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Basic ${r_token}`,
                  },
              });
  
              if (reybex.status === 200) {
                  const reybexloadData = await reybex.data[0];
                  if (reybexloadData) {
                      setReybexData(reybexloadData);
                      console.log(reybexloadData)
                      // Update headerData mit den Werten aus reybexloadData

                      if(reybexloadData){
                        if(reybexloadData.receiverCountry){
                            setHeaderData((prevHeaderData) => ({
                                ...prevHeaderData,
                                bestimmungsLand: reybexloadData.receiverCountry.code || "",
                                empfanger: {
                                    tin: "",
                                    niederlassungsNummer: "",
                                    name: reybexloadData.receiverAddressline1 || "",
                                    strasse: reybexloadData.receiverStreet1 || "",
                                    plz: reybexloadData.receiverZipPobox || "-",
                                    ort: reybexloadData.receiverCity || "",
                                    land: reybexloadData.receiverCountry.code || "",
                                  }
                            }));
                        } else{
                            setHeaderData((prevHeaderData) => ({
                                ...prevHeaderData,
                                bestimmungsLand: reybexloadData.customerCountry.code || "",
                                empfanger: {
                                    tin: "",
                                    niederlassungsNummer: "",
                                    name: reybexloadData.customer.name || "",
                                    strasse: reybexloadData.customerStreet1 || "",
                                    plz: reybexloadData.customerZipStreet || "-",
                                    ort: reybexloadData.customerCity || "",
                                    land: reybexloadData.customerCountry.code || "",
                                  }
                            }));
                        }
                      };

                      setHeaderData((prevHeaderData) => ({
                          ...prevHeaderData,
                          lrn: reybexloadData.docNumber || "",
                          referenznummerUCR: reybexloadData.docNumber || "",
                          gesamtRohmasse: reybexloadData.grossWeight || "",
                          inRechnunggestellterGesamtbetrag: reybexloadData.totalAmount || "",
                          rechnungswahrung: reybexloadData.currency.code || "",
                      }));
                  }
              }
          } catch (error) {
              console.error(error);
          }
      };
  
      GetReyBexHeaderData();
  }, [ls_number]);

  //Getting Atlasdata    
  useEffect(() => {
      const GetAtlasData = async () => {
          if (reybexData) {
              const b_accesstoken = localStorage.getItem('b_accesstoken');
              const b_token = localStorage.getItem('b_token');
  
              try {
                  const AtlasCodeRequest = {
                      query: "query($type: [String]) { getOptionsForCompany(type: $type) { type userid options{ code version description bemerkung description2 description3 description4 description5 isSelected }} }",
                      variables: {
                          type: ["104", "105", "106", "108", "110", "111", "113", "114", "115", "116", "118", "122", "123", "124", "125", "128", "129", "133", "137", "151", "152",
                                "153", "139", "410", "146", "155", "156", "157", "158", "142", "141", "136", "143", "120", "114", "140", "160","121", "138"], 
                      },
                  };


                  const AtlasAddressRequest = {
                        query: "query($type: [String]) { getExportsAddress(type: $type) { type userId addresses{ tin niederlassungsNummer name ort land ansprechName referenceNum strasse phone ansprechEmail kundenNum plz }} }",
                        variables: {
                            type: [
                            "01",
                            "02",
                            "04",
                            "05"
                            ]
                        }
                  }
  
                  const atlas = await axios.post(
                      `${Base.beo}GetData/common/codelist`,
                      JSON.stringify(AtlasCodeRequest),
                      {
                          headers: {
                              'Content-Type': 'application/json',
                              'bearerToken': b_accesstoken,
                              'token': b_token,
                          },
                      }
                  );
  
                  if (atlas.status === 200) {
                      const atlasData = await atlas.data;
                      if (atlasData) {
                          setAtlasData(atlasData.getOptionsForCompany);
                      }
                  }


                  //Get Address Data:
                  const atlasAddressData = await axios.post(
                    `${Base.beo}GetData/ausfuhr/address`,
                        JSON.stringify(AtlasAddressRequest),
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'bearerToken': b_accesstoken,
                                'token': b_token,
                            },
                        }
                    );

                    if (atlasAddressData.status === 200) {
                        const atlasAddress = await atlasAddressData.data;
                        if (atlasAddress) {
                            setAtlasAddressData(atlasAddress.getExportsAddress);
                        }
                    }


              } catch (error) {
                  console.error(error);
              }
          }
          setLoading(false);
      };
      GetAtlasData();
  }, [reybexData]);



  //refresh Data: 

  const RefreshAtlasData = async () => {
            const b_accesstoken = localStorage.getItem('b_accesstoken');
            const b_token = localStorage.getItem('b_token');

            try {
                const AtlasCodeRequest = {
                    query: "query($type: [String]) { getOptionsForCompany(type: $type) { type userid options{ code version description bemerkung description2 description3 description4 description5 isSelected }} }",
                    variables: {
                        type: ["104", "105", "106", "108", "110", "111", "113", "114", "115", "116", "118", "122", "123", "124", "125", "128", "129", "133", "137", "151", "152",
                            "153", "139", "410", "146", "155", "156", "157", "158", "142", "141", "136", "143", "120", "114", "140", "160","121"], 
                    },
                };

                const atlas = await axios.post(
                    `${Base.beo}GetData/common/codelist`,
                    JSON.stringify(AtlasCodeRequest),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'bearerToken': b_accesstoken,
                            'token': b_token,
                        },
                    }
                );

                if (atlas.status === 200) {
                    const atlasData = await atlas.data;
                    if (atlasData) {
                        setAtlasData(atlasData.getOptionsForCompany);
                    }
                }

            } catch (error) {
                console.error(error);
            }
        setLoading(false);
    };




    //mapping the Data along with the reybex and atlas data.


    const [headerData, setHeaderData] = useState({
        eoriNiederlassungsnummer: "",
        dienststellennummer: "",
        lrn: "",
        artderAnmeldung: "",
        artderAusfuhranmeldung: "",
        beteiligtenKonstellation: "",
        beforderungskostenZahlungsart: "", 
        zeitpunktderAnmeldung: "",
        massgeblichesDatum: "",
        kopfDatumdesAusgangs: "",
        zeitpunktDerGestellung: "",
        zeitpunktdesEndesderLadetatigkeit: "",
        sicherheit: "",
        besondereUmstande: "",
        inRechnunggestellterGesamtbetrag: "",
        rechnungswahrung: "",
        gestellungszollstelle: "",
        ausfuhrzollstelleDienststellennummer: "",
        ausfuhrZollstelleFurDieErganzende: "",
        vorgeseheneAusgangszollstelleDienststellennummer: "",
        tatsachlicheAusgangszollstelleDienststellennummer: "",
        artdesGeschafts: "",
        ausfuhrland: "",
        bestimmungsLand: "",
        lieferbedingungIncotermCode: "",
        lieferbedingungUnLocode: "",
        lieferbedingungOrt: "",
        lieferbedingungLand: "",
        lieferbedingungText: "",
        containerIndikator: "",
        inlandischerVerkehrszweig: "",
        verkehrszweigAnDerGrenze: "",
        gesamtRohmasse: "",
        referenznummerUCR: "",
        anmelder: {
            tin: "",
            niederlassungsNummer: "",
            name: "",
            strasse: "",
            plz: "",
            ort: "",
            land: "",
            phone: "",
            ansprechName: "",
            ansprechEmail: ""
        },
        empfanger: {
            tin: "",
            niederlassungsNummer: "",
            name: "",
            strasse: "",
            plz: "",
            ort: "",
            land: ""
        },
        vertreter: {
            tin: "",
            niederlassungsNummer: "",
            phone: "",
            ansprechName: "",
            ansprechEmail: ""
        },
        subunternehmer: {
            tin: "",
            niederlassungsNummer: "",
            name: "",
            strasse: "",
            plz: "",
            ort: "",
            land: ""
        },
        registriernummerextern: "",
        warenortArtdesOrtes: "",
        warenortArtDerOrtsbestimmung: "",
        warenortBewilligungsnummer: "",
        warenortZusatzlicheKennung: "",
        warenortUnLocode: "",
        warenortGnssBreite: "",
        warenortGnssLang: "",
        beforderungsmittelderGrenzeArt: "",
        beforderungsmittelderGrenzeKennzeichen: "",
        beforderungsmittelderGrenzeStaatszugehorigkeit: "",
      });
    
    const [bewilligung, setBewilligung] = useState({
            bewilligung: []
        }
    )


    const [warenOrt, setWarenOrt] = useState(
        {
            warenort: {
                name: "",
                strasse: "",
                plz: "",
                ort: "",
                land: "",
                phone: "",
                ansprechName: "",
                ansprechEmail: ""
            },
        }
    );

    const [suplyActors, setSuplyActors] = useState({
        lieferkettenBeteiligter: []
    })

    const [unterlageHeader, setUnterlage] = useState({
        unterlage:[]
    });


    const [sonstigerVerweis, setSonstigerVerweis] = useState({
        sonstigerVerweis:[]
    });


    const [zusätzlicheInformationen, setZusätzlicheInformationen] = useState({
        zusatzlicherInformation:[]
    });


    const [befoerderungsMittelAnDerGrenze, setBefoerderungsMittelAnDerGrenze] = useState({
        beforderungsmittelBeimAbgang: []
    });


    const [wiederEinfuhrLand, setWiederEinfuhrLand] = useState({
            wiedereinfuhr: []
    });


    //Funktionen zum Aktualisieren der Daten
    const addwiederEinfuhrLand = (newWiederEinfuhrLand) => {
        setWiederEinfuhrLand((prevWiederEinfuhrLand) => ({
            wiedereinfuhr: [...prevWiederEinfuhrLand.wiedereinfuhr, newWiederEinfuhrLand]
        }));

        console.log(wiederEinfuhrLand);
    };

    // Funktionen zum Aktualisieren der Daten
    const updateHeaderData = (newHeaderData) => {
        setHeaderData((prevHeaderData) => ({
        ...prevHeaderData,
        ...newHeaderData,
        }));
    };

    const addBewilligungEntry = (newBewilligungData) => {
        setBewilligung((prevBewilligungData) => ({
            bewilligung: [...prevBewilligungData.bewilligung, newBewilligungData]
        }));
    };

    const addSuplyActorEntry = (newSuplyActorData) => {
        setSuplyActors((prevSuplyActorData) => ({
            lieferkettenBeteiligter: [...prevSuplyActorData.lieferkettenBeteiligter, newSuplyActorData]
        }));
    };

    const addUnterlageHeader = (newUnterlageData) =>{
        setUnterlage((prevUnterlageData) => ({
            unterlage: [...prevUnterlageData.unterlage, newUnterlageData]
        }));
    }


    const addSonstigerVerweis = (newSonstigerVerweis) =>{
        setSonstigerVerweis((prevSonstigerVerweis) => ({
            sonstigerVerweis: [...prevSonstigerVerweis.sonstigerVerweis, newSonstigerVerweis]
        }));
    }


    const addZusätzlicheInformationen = (newZusätzlicheInformation) =>{
        setZusätzlicheInformationen((prevZusätzlicheInformation) => ({
            zusatzlicherInformation: [...prevZusätzlicheInformation.zusatzlicherInformation, newZusätzlicheInformation]
        }));
    };


    const addBefoerderungsMittelAnDerGrenze = (newBefoerderungsMittelAnDerGrenze) =>{
        setBefoerderungsMittelAnDerGrenze((prevBefoerderungsMittelAnDerGrenze) => ({
            beforderungsmittelBeimAbgang: [...prevBefoerderungsMittelAnDerGrenze.beforderungsmittelBeimAbgang, newBefoerderungsMittelAnDerGrenze]
        }));
    };


    //ReyBex View: 
    const [filteresLs, setFilteredLs] = useState([]);


    //Handle option Set
    const [cardSlider, setCardSlider] = useState(false);
    const [cardOptions, setCardOptions] = useState([]);
    const [cardHeader, setCardHeader] = useState("");
    const [cardLoader, setCardLoader] = useState(false);
    

    const [activeCodelist, setActiveCodelist] = useState();


    const handleCardSlider = async (name, code, close=null) => {
        setCardLoader(true);
        setCardSlider(!cardSlider);

        setActiveCodelist(code)
        if(name){
            setCardHeader(name);
        }

        if (code && close === null){

            const RequestData = {
                query: "query ($type: [String]) { getOptions(type: $type) {type options {code module version language description bemerkung description2 description3 description4 description5}}} ",
                    variables: {
                    type: [`${code}`]
                    }
                }


            const GetCompanyList = {
                query: "query($type: [String]) { getOptionsForCompany(type: $type) { type userid options{ code version description bemerkung description2 description3 description4 description5 isSelected }} }",
                variables: {
                    type: [`${code}`], 
                },
            };
            
    
            const handleRequest = async () => {
                const AllOptions = await axios.post(`${Base.beo}GetData/common/codelist`,JSON.stringify(RequestData),{
                    headers: {
                        'Content-Type': 'application/json',
                        'bearerToken': localStorage.getItem('b_accesstoken'),
                        'token': localStorage.getItem('b_token')
                    }
                })


                const CompanyDataOptions = await axios.post(`${Base.beo}GetData/common/codelist`,JSON.stringify(GetCompanyList),{
                    headers: {
                        'Content-Type': 'application/json',
                        'bearerToken': localStorage.getItem('b_accesstoken'),
                        'token': localStorage.getItem('b_token')
                    }
                })

        
                const allOptionsResponse = await AllOptions.data;
                const companyOptions = await CompanyDataOptions.data;

                if (allOptionsResponse.getOptions[0].options.length > 0 && companyOptions.getOptionsForCompany[0].options.length > 0) {
                    const AllOptions = allOptionsResponse.getOptions[0].options;
                    const CompanyOptions = companyOptions.getOptionsForCompany[0].options;
                
                    const companyOptionsMap = new Map(
                        CompanyOptions.map(option => [option.code, option.isSelected || false])
                    );
                
                    const updatedOptions = AllOptions.map(option => ({
                        ...option,
                        isSelected: companyOptionsMap.has(option.code)
                            ? companyOptionsMap.get(option.code)
                            : false,
                        isActive: companyOptionsMap.has(option.code)
                    }));
                
                    console.log("Updated Options: ", updatedOptions);
                    setCardOptions(updatedOptions);
                    setCardLoader(false);
                }
            }
            handleRequest();
        }
    
    };



    return (
        <FormDataContext.Provider value={{
            atlasData, 
            atlasAddressData,
            headerData,
            setHeaderData, 
            updateHeaderData,
            addBewilligungEntry, 
            bewilligung,
            addSuplyActorEntry,
            suplyActors,
            unterlageHeader, 
            addUnterlageHeader,
            addSonstigerVerweis,
            sonstigerVerweis,
            zusätzlicheInformationen,
            addZusätzlicheInformationen,
            befoerderungsMittelAnDerGrenze,
            addBefoerderungsMittelAnDerGrenze,
            setLsNumber,
            ls_number,
            setFilteredLs,
            filteresLs,
            warenOrt, 
            setWarenOrt,
            wiederEinfuhrLand,
            addwiederEinfuhrLand,

            //Card slider: 
            handleCardSlider,
            cardSlider, 
            setCardSlider,
            cardHeader,
            cardLoader,
            cardOptions,
            setCardOptions,
            activeCodelist,


            //Refresh ATLAS - Codelist: 
            RefreshAtlasData,


            //active LS:
            reybexData
            }}>

            {loading ? <PageLoader/> : (
              <>
                  {children}
              </>
            )}
        </FormDataContext.Provider>
    );
};

export const useFormData = () => useContext(FormDataContext);
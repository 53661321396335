import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { Input } from "../../../../Components/Input/TextField/Input"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"

//Importing the Table component: 
import { 
    TableContainer,
    TableHeader,
    TableBody,
    TableHeaderItem,
    TableItem,
    TableRow

 } from "../../../../Components/DataDisplay/Table/Table"


//Table
import { useEffect, useState } from "react"

export const PermissionData = () => {

    //Main States
    const {bewilligung, addBewilligungEntry, atlasData} = useFormData();
    const [atlasCodeList, setAtlasCodeList] = useState([]);

    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [newBewilligungData, setNewBewilligungData] = useState({
        "art": "", 
        "referenznummer": "",
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBewilligungData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewBewilligungData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddBewilligung = (e) =>{
        e.preventDefault();
        addBewilligungEntry(newBewilligungData);
        setNewBewilligungData({ art: '', referenznummer: '' });
    }

    console.log(bewilligung);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Bewilligung" type="center">
                    <Container size="small">
                        <Input
                            label="Anzahl der Einträge"
                            isDisabled={true}
                            onChange={handleInputChange}
                            name="anzahlEintrage"
                            value={bewilligung.anzahlEintrage}
                        />
                        <DropDownButton
                            name='Art'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '139')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('art', item)}
                            dropname='art'
                            optionName={"Art der Bewilligung"}
                            codelist={"139"}
                        />
                        <Input
                            label="Referenznummer"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="referenznummer"
                            value={bewilligung.referenznummer}
                        />

                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddBewilligung}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>
                    <TableHeader>
                        <TableHeaderItem>Nr.</TableHeaderItem>
                        <TableHeaderItem>Art</TableHeaderItem>
                        <TableHeaderItem>Referenznummer</TableHeaderItem>
                    </TableHeader>

                    <TableBody>
                        {bewilligung.bewilligung.length > 0 ? (

                                bewilligung.bewilligung.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableItem>{index + 1}</TableItem>
                                        <TableItem>{item.art}</TableItem>
                                        <TableItem>{item.referenznummer}</TableItem>
                                    </TableRow>
                                ))
                        ) : (
                            <span>Keine Daten vorhanden</span>
                        )}
                    </TableBody>
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
import './TabHandler.css';

export const TabHandler = ({children}) => {
    return(
        <>
            <div className="bap_tab_handler_wrapper">
                <div className="bap_tab_handler_content_box">
                    <ul>
                        {children}
                    </ul>
                </div>
            </div>
        </>
    );
}

export const TabItem = ({children, goTo, active=false}) => {
    return(
        <>
        <li className={`bap_tab_item_box ${active ? 'bap_tab_active' : ''}`} onClick={goTo}>
            <span>{children}</span>
        </li>
        </>
    )
}
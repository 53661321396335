import './CardSlider.css';
import { MdClose } from "react-icons/md";


//logic

export const CardSliderContainer = ({children}) => {
    return(
        <div className="bap_card_slider_container">
            {children}
        </div>
    )
};


export const CardSlider = ({children, header, onClose}) => {

    //handle Close
    const handleClose = () => {
        onClose();
    }

    return(
        <div className="bap_card_slider_card">
            <div className="bap_card_slider_header_bar">
                <h3>{header}</h3>
                <div className="bap_close_card_slider" onClick={handleClose}>
                    <MdClose />
                </div>
            </div>

            <div className="bap_card_slider_content">
                {children}
            </div>
        </div>
    );
};
import atlas_countries from '../../Config/atlas_countries.json';

// WICHTIG: Es wird nach den Bestimmungsländern gefiltert,
// welche in ATLAS ausgewählt werden können.
export const FilterLsForExport = async ({ ls_data = [] }) => {
    if (!ls_data || ls_data.length === 0) {
        return [];
    }

    const validLs = ls_data.filter((ls) => {
        // Sicherstellen, dass entweder receiverCountry oder customerCountry genutzt wird
        const country = ls.receiverCountry ?? ls.customerCountry;

        // Prüfen, ob country und country.code definiert sind
        if (!country || !country.code) {
            console.warn("Ungültiges Country-Objekt:", country);
            return false;
        }

        // Überprüfen, ob country.code in atlas_countries vorhanden ist
        return atlas_countries.options.some((option) => option.code === country.code);
    });

    return validLs;
};

import axios from 'axios';
import './setOptionsList.css';

//import API Base
import Base from '../../../Api/Base.json';
import { useFormData } from '../../../Utils/Contexts/FormDataContext';


export const OptionsListContainer = (
    {children}
) => {
    return(
        <div className="bap_options_list_container">
            {children}
        </div>
    )
};


export const OptionItemList = ({children}) => {
    return(
        <ul className="bap_option_item_list">
            {children}
        </ul>
    );
}


export const OptionItem = ({children, status, defaultOption, item, type}) => {

    //Add or remove option to OptionList
    const {cardOptions, setCardOptions, RefreshAtlasData} = useFormData();

    const SetAsDefault = async () => {
        console.log("Aktuelles Item und Typ:", item);
    
        const RequestData = {
            userid: "FBurkhard",
            mandantId: "FTAM",
            selectedOption: {
                type: `${type}`,
                options: [
                    {
                        code: `${item.code}`,
                        default: item.isDefault === true ? false : true
                    }
                ]
            }
        };

        console.log("ICH BIN DER REQUEST:", RequestData);
    
        try {
            const res = await axios.post(
                `${Base.beo}defaultOption`,
                JSON.stringify(RequestData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        bearerToken: localStorage.getItem("b_accesstoken"),
                        token: localStorage.getItem("b_token")
                    }
                }
            );
    
            const response = await res.data;

    
            if (res.status === 201) {
                const updatedDefault = item.isSelected === true ? false : true;

                // Aktualisiere die cardOptions
                if(item.isActive === true){
                    const newOptions = cardOptions.map((option) => {
                        if (option.code === item.code) {
                            return {
                                ...option,
                                isSelected: updatedDefault 
                            };
                        }
                        return {
                            ...option,
                            isSelected: false
                        };
                    });
        
                    setCardOptions(newOptions);
        
                    console.log("Aktualisierte Optionen:", newOptions);

                }else{
                    console.log("Die Option ist inaktiv und kann nicht aktualisiert werden.");
                }
            } else {
                console.error("Fehler beim Aktualisieren der Option:", response.statusText);
            }
        } catch (error) {
            console.error("Fehler bei der API-Anfrage:", error.message);
        }
    };




    //Set active // inactive
    const setOptionActive = async () => {

        const RequestData = {
            userid: "FBurkhard",
            mandantId: "FTAM",
            selectedOption: {
                type: `${type}`,
                options: [
                    {
                        code: `${item.code}`
                    }
                ]
            }
        };

        try{
            const res = await axios.post(
                `${Base.beo}defaultOption`,
                JSON.stringify(RequestData),
                {
                    headers: {
                        "Content-Type": "application/json",
                        bearerToken: localStorage.getItem("b_accesstoken"),
                        token: localStorage.getItem("b_token")
                    }
                }
            );


            const response = await res.data;

            console.log("Response:", response);

            if(res.status === 201){
                const updatedActive = item.isActive === true ? false : true;

                // Aktualisiere die cardOptions
                const newOptions = cardOptions.map((option) => {
                    if (option.code === item.code) {
                        return {
                            ...option,
                            isActive: updatedActive
                        };
                    }
                    return option;
                });


                console.log("Aktualisierte Optionen:", newOptions);

                setCardOptions(newOptions);


                if(newOptions){
                    RefreshAtlasData(); //Refresh Data
                }

            }
        }catch(err){
            console.log(err)
        }
    }


    return(
        <li className="bap_option_item" >

            <div className="bap_item_is_default" onClick={SetAsDefault}>
                {defaultOption === true ? (
                    <div className="bap_item_is_default_icon" >

                    </div>
                ) : (
                    <div className="bap_item_is_no_default_icon" onClick={SetAsDefault}>
                            
                    </div>
                )}

                <span>{children}</span>
            </div>

            {/*There we need a select action*/}
            {status === true ? (
                <div className="bap_item_status_for_active" onClick={setOptionActive}>
                    <span>Aktiv</span>
                </div>
            ) : (
                <div className="bap_item_status_for_inactive" onClick={setOptionActive}>
                    <span>Inaktiv</span>
                </div>
            )}
        </li>
    );
}



import './Index.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

//importing the Views
import AtlasPluginSetUp from './Views/AppSetup/AtlasPluginSetUp';
import CreateAtlasMessage from './Views/CreateAtlasMessage/CreateAtlasMessage';
import FallBackPage from './Views/FallBackPage/FallBackPage';
import { FormDataProvider } from './Utils/Contexts/FormDataContext';
import CreateAtlasMessagePosition from './Views/CreateAtlasMessage/CreateAtlasMessagePosition';
import { PositionDataProvider } from './Utils/Contexts/PositionDataContext';
import BeoAtlasAusfuhrStatus from './Views/ListBeoData/ListBeoData';

//ReyBex lIST ITEMS
import ReyBexDataListIems from './Views/ViewReyBexData/ReyBexDataDisplay';

function App() {
  return (
    <>
    <FormDataProvider>
        <PositionDataProvider>
          <Router>
            <Routes>
              {/*Pages for the ATLAS - MESSAGE GENERATION*/}
              <Route path='/app/setup' element={<AtlasPluginSetUp/>}/>

              {/*Display Atlas Message Pages*/}
              <Route path='/app/reybex/listitems' element={<ReyBexDataListIems/>}/>
              <Route path='/app/beo/listmessages' element={<BeoAtlasAusfuhrStatus/>}/>

              {/*Create Atlas Message Pages*/}
              <Route path='/app/create/atlasmessage' element={<CreateAtlasMessage/>}/>
              <Route path='/app/create/atlasmessage/position' element={<CreateAtlasMessagePosition/>}/>

              {/*FallBackPage*/}
              <Route path='*' element={<FallBackPage/>}/>
              <Route path='/' element={<Navigate to="/app/setup" replace />}/>
            </Routes>
          </Router>
        </PositionDataProvider>
    </FormDataProvider>
    </>
  );
}

export default App;

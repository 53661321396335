import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { Input } from "../../../../Components/Input/TextField/Input"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"


//Table
import { 
    TableContainer,
    TableHeader,
    TableBody,
    TableHeaderItem,
    TableItem,
    TableRow
 } from "../../../../Components/DataDisplay/Table/Table"
import { useEffect, useState } from "react"

export const UnterlageInformationHeader = () => {

    const {unterlageHeader, addUnterlageHeader, atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [unterlage, setUnterlage] = useState({
        art: "",
        qualifikator: "",
        referenznummer: "",
        zeilenPositionsnummer: "",
        name: "",
        datumDerAusstellung: "",
        gultigkeitsdatum: ""
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUnterlage((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setUnterlage((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddUnterlageInHeader = (e) =>{
        e.preventDefault();
        addUnterlageHeader(unterlage);
        setUnterlage({
            art: "",
            qualifikator: "",
            referenznummer: "",
            zeilenPositionsnummer: "",
            name: "",
            datumDerAusstellung: "",
            gultigkeitsdatum: ""
        });
    }

    console.log(unterlageHeader);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Unterlage" type="center">
                    <Container size="small">
                        <DropDownButton
                            name='Art'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '155')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('art', item)}
                            dropname='art'
                            optionName={"Art der Unterlage"}
                            codelist={"155"}
                        />
                        <Input
                            label="Quialifikator"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="qualifikator"
                            value={unterlage.qualifikator}
                        />
                        <Input
                            label="Referenznummer"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="referenznummer"
                            value={unterlage.referenznummer}
                        />
                        <Input
                            label="Positionsnummer im Dokument"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="zeilenPositionsnummer"
                            value={unterlage.zeilenPositionsnummer}
                        />
                        <Input
                            label="Name der ausstellenden Behörde"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="name"
                            value={unterlage.name}
                        />
                        <Input
                            label="Datum der Ausstellung"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="datumDerAusstellung"
                            type="date"
                            value={unterlage.datumDerAusstellung}
                        />
                        <Input
                            label="Gültigkeitsdatum"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="gultigkeitsdatum"
                            type="date"
                            value={unterlage.gultigkeitsdatum}
                        />


                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddUnterlageInHeader}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>

                    <TableHeader>
                        <TableHeaderItem>Nr</TableHeaderItem>
                        <TableHeaderItem>Art</TableHeaderItem>
                        <TableHeaderItem>Qualifikator</TableHeaderItem>
                        <TableHeaderItem>Referenznummer</TableHeaderItem>
                        <TableHeaderItem>Positionsnummer</TableHeaderItem>
                        <TableHeaderItem>Ausstellenden Behörde</TableHeaderItem>
                        <TableHeaderItem>Datum der Ausstellung</TableHeaderItem>
                        <TableHeaderItem>Gültigkeitsdatum</TableHeaderItem>
                    </TableHeader>

                    {unterlageHeader.unterlage.length > 0 ? (
                        unterlageHeader.unterlage.map((item, index) => (
                            <TableBody>
                                <TableRow key={index}>
                                    <TableItem>{index +1}</TableItem>
                                    <TableItem>{item.art}</TableItem>
                                    <TableItem>{item.qualifikator}</TableItem>
                                    <TableItem>{item.referenznummer}</TableItem>
                                    <TableItem>{item.zeilenPositionsnummer}</TableItem>
                                    <TableItem>{item.name}</TableItem>
                                    <TableItem>{item.datumDerAusstellung}</TableItem>
                                    <TableItem>{item.gultigkeitsdatum}</TableItem>
                                </TableRow>
                            </TableBody>
                        ))
                    ) : (
                        <span>Keine Daten vorhanden</span>
                    )}
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
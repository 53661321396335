import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { Input } from "../../../../Components/Input/TextField/Input"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"


//Table
import { TableContainer } from "../../../../Components/DataDisplay/Table/Table"
import { useEffect, useState } from "react"

export const ZusätzlicheInformationenHeader = () => {

    const [atlasCodeList, setAtlasCodeList] = useState([]);
    const {zusätzlicheInformationen, addZusätzlicheInformationen, atlasData} = useFormData();

    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [newZusätzlicheInformation, setNewZusätzlicheInformation] = useState({
        code: "",
        text: "",
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewZusätzlicheInformation((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewZusätzlicheInformation((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddUnterlageInHeader = (e) =>{
        e.preventDefault();
        addZusätzlicheInformationen(newZusätzlicheInformation);
        setNewZusätzlicheInformation({
            code: "", 
            text: ""
        });
    }

    console.log(zusätzlicheInformationen);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Zusätzliche Informationen" type="center">
                    <Container size="small">
                        <DropDownButton
                            name='Code'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '157')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('code', item)}
                            dropname='code'
                            optionName={"Zusätzliche Informationen"}
                            codelist={"157"}
                        />
                        <Input
                            label="Text"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="text"
                            value={newZusätzlicheInformation.text}
                        />
                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddUnterlageInHeader}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>
                    {zusätzlicheInformationen.zusatzlicherInformation.length > 0 ? (
                        zusätzlicheInformationen.zusatzlicherInformation.map((item, index) => (
                            <div key={index}>
                                <span>{item.code}</span>
                                <span>{item.text}</span>
                                <span>{item.identifikationsnummer}</span>
                            </div>
                        ))
                    ) : (
                        <span>Keine Daten vorhanden</span>
                    )}
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
import './FormBox.css';

export const FormBox = ({children, header, inlineHeader, type=""}) => {

    const CenteredStyle = {
        displFlex: "center",
        alignItems: "center",
        justifyContent: "center"
    }

    return(
        <>
        {header ? <h4 className='bap_header_section_form_box'>{header}</h4> : null}
        <div className="bap_form_box_wrapper" style={type === "center" ? CenteredStyle : null}>
            {inlineHeader ? <span className='bap_FormBox_inline_header'>{inlineHeader}</span> : null}
            {children}
        </div>
        </>
    )
}
import './SetUpCard.css';

export const SetUpCard = ({children, header}) => {
    return(
        <>
        <div className="bap_setup_card_wrapper">
            <h4>{header}</h4>
            {children}
        </div>
        </>
    )
};
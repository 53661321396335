import React, { useEffect, useState } from 'react';
import { BaseWrapper } from '../../Components/Layout/BaseLayouts/Base/Base';
import { ToolBar} from '../../Components/Layout/BaseLayouts/Base/Base';
import { ButtonGroup, Button } from '../../Components/Button/Buttons';
import { ListContentBase } from '../../Components/Layout/BaseLayouts/Base/Base';

//Loading page
import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

import Base from '../../Api/Base.json';

//Importing the Table 
import { 
    TableContainer, 
    TableHeader, 
    TableBody, 
    TableHeaderItem, 
    TableItem,
    TableRow
} from '../../Components/DataDisplay/Table/Table';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function BeoAtlasAusfuhrStatus() {

    let navigate = useNavigate();


    //Getting all the Ausfuhrdata
    const[atlasMessages, setAtlasMessages] = useState([]);
    const[loading, setLoading] = useState(false);
    useEffect(() => {
        const b_accesstoken = localStorage.getItem("b_accesstoken")
        const b_token = localStorage.getItem("b_token")
        setLoading(true)
        const HandleAtlasAusfuhrStatus = async () => {
            if(b_token && b_accesstoken){

                const MessageRequest = {
                    query: "query ($filter: StatusFilterDTO,$page: GraphqlPageRequestDTO ) { exportsStatus(filter: $filter,page: $page) {statusData { nachrichtennummer datum mrnlrn empfanger pdf{relPdf notPdf}} totalPages totalRows} }",
                    variables: {
                      filter: {
                        nachrichtennummer: "",
                        datum: "",
                        mrnlrn: "",
                        empfanger: ""
                      },
                      page: {
                        startLimit: 0,
                        endLimit: 25
                      }
                    }
                  }

                try{
                    const res = await axios.post(`${Base.beo}GetData/ausfuhr/status`,
                        JSON.stringify(MessageRequest),
                        {
                            headers:{
                                'Content-Type': 'application/json',
                                'bearerToken': b_accesstoken,
                                'token': b_token
                            }
                        }
                    )
                    if(res.status === 200){
                        const data = await res.data
                        setAtlasMessages(data.exportsStatus.statusData)
                    }
    
                }catch(err){
                    console.log("Oh nooo..", err)
                    toast.error("Failed to fetch data")
                }
            }
            setLoading(false)
        }
        HandleAtlasAusfuhrStatus()
    }, [])


    //Handle PDF Creation
    const handleDownloadPdf = (currentPdf, pdfName) => {
        console.log(currentPdf);
      
        // Base64-String in Binärdaten konvertieren
        const binary = atob(currentPdf);
        const len = binary.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary.charCodeAt(i);
        }
      
        // Erstelle ein Blob-Objekt für das PDF
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
      
        // Erstelle einen temporären Link zum Herunterladen
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${pdfName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };



    return ( 
        <>
        <BaseWrapper>
        {loading === true ? <PageLoader /> : null}
            <ToolBar>
                <ButtonGroup>
                    <Button onClick={() => navigate("/app/reybex/listitems")}>Auftragsübersicht</Button>
                </ButtonGroup>
            </ToolBar>
            <ListContentBase>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderItem>Nr.</TableHeaderItem>
                        <TableHeaderItem>LRN</TableHeaderItem>
                        <TableHeaderItem>Empfänger</TableHeaderItem>
                        <TableHeaderItem>Nachrichten Datum</TableHeaderItem>
                        <TableHeaderItem>Überlassung</TableHeaderItem>
                    </TableHeader>

                    <TableBody>
                        {atlasMessages?.map((message, index) => (
                            <>
                            <TableRow>
                                <TableItem>{index +1}</TableItem>
                                <TableItem>{message.mrnlrn}</TableItem>
                                <TableItem>{message.empfanger}</TableItem>
                                <TableItem>{message.datum}</TableItem>
                                <TableItem>
                                    {message.pdf.relPdf.length === 0 ? (
                                        <>
                                        <span>Kein PDF</span>
                                        </>
                                    ) : (
                                        <>
                                        <Button onClick={() => handleDownloadPdf(message.pdf.relPdf[0], message.mrnlrn)}>Ausfuhrbegleitdokument</Button>
                                        </>
                                    )}
                                </TableItem>
                            </TableRow>   
                            </>
                        ))}
                    </TableBody>

                </TableContainer>

            </ListContentBase>

        </BaseWrapper>
        </>
     );
}

export default BeoAtlasAusfuhrStatus;
import './FormWrapper.css';

export const FormWrapper = ({children, action=""}) => {
    return(
        <>
        <form className="bap_main_form_wrapper" action={action}>
            {children}
        </form>
        </>
    )
}
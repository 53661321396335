import { SetUpCard } from "../../../Components/Layout/SetUpCard/SetUpCard";
import { FormWrapper } from "../../../Components/Layout/Forms/FormWrapper/FormWrapper";

import Base from '../../../Api/Base.json';
import axios from "axios";

//Inputs
import { InlineInput } from "../../../Components/Input/TextField/Input";
import { Button } from "../../../Components/Button/Buttons";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function BeoSetUp () {

// Funktion zum Verarbeiten der Eingaben (onChange-Handler)

    //navigate
    const navigate = useNavigate();


    //Loading
    const [loading, setLoading] = useState(false);

    const [BeoAuth, setBeoAuth] = useState({
        Token: '',
        Username: '',
        Password: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBeoAuth({
            ...BeoAuth, 
            [name]: value 
        });
    };


    //Handle Submit

    const headers = {
        'Authorization': 'Basic ' + btoa(`${BeoAuth.Username}:${BeoAuth.Password}`)
    }

    const handleLogin = async (e) => {
        e.preventDefault();

        setLoading(true);
        if(BeoAuth.Username && BeoAuth.Password && BeoAuth.Token){
            try{

                const res = await axios.post(`${Base.beo}authenticate`, null, {headers})
    
                if(res.status === 200){
                    localStorage.setItem('b_accesstoken', res.data.accessToken);
                    localStorage.setItem('b_token', BeoAuth.Token);
                    toast.success("Anmeldung erfolgreich", {
                        position: "bottom-right",
                    });

                    if(localStorage.getItem('b_accesstoken') && localStorage.getItem('b_token')){
                        navigate("/app/reybex/listitems");
                    }

                }
                
    
            }catch(err) {
                console.log(err);
            }
        }else{
            toast.error("Bitte füllen Sie alle Felder aus", {
                position: "bottom-right",
            })
        }
        setLoading(false);
    }


    return ( 
        <>
        <SetUpCard header="Beo-Atlas">
            <FormWrapper action='submit'>
                <div className="bap_form_wrapper_setup">
                    <InlineInput 
                        label='Atlas-Api-Token'
                        value={BeoAuth.Token}
                        onChange={handleInputChange}
                        name='Token'
                    />
                    <InlineInput 
                    label='Benutzername'
                    value={BeoAuth.Username}
                    onChange={handleInputChange}
                    name='Username'
                    />
                    <InlineInput
                    label='Passwort'
                    type='password'
                    value={BeoAuth.Password}
                    onChange={handleInputChange}
                    name='Password'
                    />
                    <Button type='submit'onClick={handleLogin} isLoading={loading === true ? true : false}>Anmelden</Button>
                </div>
            </FormWrapper>
        </SetUpCard>
        </>
     );
}

export default BeoSetUp ;
import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { Input } from "../../../../Components/Input/TextField/Input"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"


//Table
import { TableContainer } from "../../../../Components/DataDisplay/Table/Table"
import { useEffect, useState } from "react"

export const BeförderungeMittelAmAbgang = () => {

    const [atlasCodeList, setAtlasCodeList] = useState([]);
    const {befoerderungsMittelAnDerGrenze, addBefoerderungsMittelAnDerGrenze, atlasData} = useFormData();

    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [newBefoerderungsMittelAnDerGrenze, setNewBefoerderungsMittelAnDerGrenze] = useState({
        artderIdentifikation: "",
        kennzeichen: "",
        staatszugehorigkeit: ""
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBefoerderungsMittelAnDerGrenze((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewBefoerderungsMittelAnDerGrenze((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddUnterlageInHeader = (e) =>{
        e.preventDefault();
        addBefoerderungsMittelAnDerGrenze(newBefoerderungsMittelAnDerGrenze);
        setNewBefoerderungsMittelAnDerGrenze({
            artderIdentifikation: "",
            kennzeichen: "",
            staatszugehorigkeit: ""
        });
    }

    console.log(befoerderungsMittelAnDerGrenze);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Beförderungsmittel am Abgang" type="center">
                    <Container size="small">
                        <DropDownButton
                            name='Art der Identifikation'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '158')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('artderIdentifikation', item)}
                            dropname='artderIdentifikation'
                            codelist={"158"}
                            optionName={"Art der Identifikation"}
                        />
                        <Input
                            label="Kennzeichen"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="kennzeichen"
                            value={newBefoerderungsMittelAnDerGrenze.kennzeichen}
                        />
                        <DropDownButton
                            name='Staatszugehörigkeit'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '124')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('staatszugehorigkeit', item)}
                            dropname='staatszugehorigkeit'
                            codelist={"124"}
                            optionName={"Staatszugehörigkeit"}
                        />
                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddUnterlageInHeader}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>
                    {befoerderungsMittelAnDerGrenze.beforderungsmittelBeimAbgang.length > 0 ? (
                        befoerderungsMittelAnDerGrenze.beforderungsmittelBeimAbgang.map((item, index) => (
                            <div key={index}>
                                <span>{item.kennzeichen}</span>
                                <span>{item.staatszugehorigkeit}</span>
                                <span>{item.identifikationsnummer}</span>
                            </div>
                        ))
                    ) : (
                        <span>Keine Daten vorhanden</span>
                    )}
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
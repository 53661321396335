import axios from 'axios';
import React, {createContext, useState, useContext, useEffect } from 'react';
import Base from '../../Api/Base.json';
import { useFormData } from './FormDataContext';
import { toast } from 'react-toastify';

import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

const PositionDataContext = createContext();

export const PositionDataProvider = ({children}) => {

    const { ls_number } = useFormData();
 
    // Data for Reybex and Atlas positions
    const [reybexPositionData, setReybexPositionData] = useState([]);
    const [atlasPositionData, setAtlasPositionData] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Get the Reybex data
        const GetReyBexHeaderData = async () => {
            const ID = ls_number.ls_id;

            setLoading(true);
            const r_token = localStorage.getItem('r_token');
            try {
                const reybex = await axios.get(`${Base.reybex}domains/salesItem?salesHead.id=${ID}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Basic ${r_token}`,
                    },
                });

                if (reybex.status === 200) {
                    const reybexPositionData = await reybex.data;
                    if (reybexPositionData) {
                        setReybexPositionData(reybexPositionData);
                    }
                }
            } catch (error) {
                console.error(error);
            }
            setLoading(false);
        };

        GetReyBexHeaderData();
    }, [ls_number.ls_id]);


    const [PosBewilligung, setPosBewilligung] = useState([{bewilligung: []}])
    const [PosUnterlage, setPosUnterlage] = useState([{unterlage: []}])
    const [PosVerpackung, setPosVerpackung] = useState([{verpackung: []}])



    //Data Mapping ATLAS HEADER und ReyBex-Daten auf die Positionen
    useEffect(() => {
        if (reybexPositionData) {
            setAtlasPositionData((prevData) => 
                reybexPositionData.map((reyPos, index) => {
                    const existingData = prevData.find(
                        (pos) => pos.warePositionsnummer === index + 1
                    ) || {}; // Behalte bestehende Daten oder initialisiere leer
    
                    return {
                        ...existingData, // Bestehende Daten erhalten
                        warePositionsnummer: index + 1 || "",
                        statistischerWert: existingData.statistischerWert || "",
                        artdesGeschafts: existingData.artdesGeschafts || "",
                        ausfuhrLand: existingData.ausfuhrLand || "",
                        referenznummerUCR: existingData.referenznummerUCR || "",
                        registriernummerextern: existingData.registriernummerextern || "",
                        beantragtesVerfahren: existingData.beantragtesVerfahren || "",
                        vorhergehendesVerfahren: existingData.vorhergehendesVerfahren || "",
                        zusatzlichesVerfahren: existingData.zusatzlichesVerfahren || "",
                        ursprungsland: existingData.ursprungsland || "",
                        ursprungsVersendungsregion: existingData.ursprungsVersendungsregion || "",
                        wareWarenbezeichnung: reyPos.material.name || existingData.wareWarenbezeichnung || "",
                        warecusnummer: existingData.warecusnummer || "",
                        wareWarennummerKN8: existingData.wareWarennummerKN8 || "",
                        wareRohmasse: existingData.wareRohmasse || "",
                        wareEigenmasse: reyPos.netWeight || existingData.wareEigenmasse || "",
                        mengeinbesondererMabeinheit: existingData.mengeinbesondererMabeinheit || "",
                        beforderungskostenZahlungsart: existingData.beforderungskostenZahlungsart || "",
                        standardaustauschErsatzwarenverkehr: existingData.standardaustauschErsatzwarenverkehr || "",
                        datumderWiedereinfuhr: existingData.datumderWiedereinfuhr || "",
                        zolllagerLrn: existingData.zolllagerLrn || "",
                        zolllagerBewilligungArt: existingData.zolllagerBewilligungArt || "",
                        zolllagerBewilligungReferenznummer: existingData.zolllagerBewilligungReferenznummer || "",
                        vereinfachtErteilteBewilligung: existingData.vereinfachtErteilteBewilligung || "",
                        bewilligungArt: existingData.bewilligungArt || "",
                        bewilligungReferenznummer: existingData.bewilligungReferenznummer || "",
                        uberwachungsZollstelleReferenznummer: existingData.uberwachungsZollstelleReferenznummer || "",
                        bewilligung: PosBewilligung[index]?.bewilligung || existingData.bewilligung || [],
                        verpackung: PosVerpackung[index]?.verpackung || existingData.verpackung || [],
                        unterlage: PosUnterlage[index]?.unterlage || existingData.unterlage || [],
                    };
                })
            );
        } else {
            console.error("ERROR: reybexPositionData is undefined.");
        }
    }, [reybexPositionData, PosBewilligung, PosVerpackung, PosUnterlage]);
    

// Funktion um eine neue Bewilligung zu einer Position hinzuzufügen
    const addBewilligunPos = (warePositionsnummer, newBewilligungData) => {
        setPosBewilligung((prevBewilligungData) => {
            const updatedBewilligung = [...prevBewilligungData];
            const posIndex = warePositionsnummer - 1;

            if (!updatedBewilligung[posIndex]) {
                updatedBewilligung[posIndex] = { bewilligung: [] };
            }

            updatedBewilligung[posIndex].bewilligung = [
                ...updatedBewilligung[posIndex].bewilligung,
                newBewilligungData
            ];
            return updatedBewilligung;
        });
    };


    // Funktion um eine neue Bewilligung zu einer Position hinzuzufügen
    const addVerpackungPos = (warePositionsnummer, newVerpackungData) => {
        setPosVerpackung((prevVerapckungData) => {
            const updatedVerpackungData = [...prevVerapckungData]; // Kopiere die vorherige Liste der Bewilligungen
            const posIndex = warePositionsnummer - 1; // Positionsnummer als Index

            // Wenn noch keine Bewilligung für diese Position existiert, initialisiere sie
            if (!updatedVerpackungData[posIndex]) {
                updatedVerpackungData[posIndex] = { verpackung: [] };
            }

            // Füge die neue Bewilligung zur entsprechenden Position hinzu
            updatedVerpackungData[posIndex].verpackung = [
                ...updatedVerpackungData[posIndex].verpackung,
                newVerpackungData
            ];
            return updatedVerpackungData;
        });
    };


    const addUnterlagePos = (warePositionsnummer, newUntrlageData) => {
        setPosUnterlage((prevUnterlageData) => {
            const updatedUnterlageData = [...prevUnterlageData]; // Kopiere die vorherige Liste der Bewilligungen
            const posIndex = warePositionsnummer - 1; // Positionsnummer als Index

            // Wenn noch keine Bewilligung für diese Position existiert, initialisiere sie
            if (!updatedUnterlageData[posIndex]) {
                updatedUnterlageData[posIndex] = { unterlage: [] };
            }

            // Füge die neue Bewilligung zur entsprechenden Position hinzu
            updatedUnterlageData[posIndex].unterlage = [
                ...updatedUnterlageData[posIndex].unterlage,
                newUntrlageData
            ];
            return updatedUnterlageData;
        });
    };


    const updateSpecificPosition = (warePositionsnummer, updatedData) => {
        setAtlasPositionData((prevPositions) => 
            prevPositions.map((position) =>
                position.warePositionsnummer === warePositionsnummer
                    ? { ...position, ...updatedData }  // Position aktualisieren
                    : position                         // Andere Positionen unverändert lassen
            )
        );
    };

    const [activePosition, setActivePosition] = useState({});

    useEffect(() => {
        if (activePosition?.warePositionsnummer) {
            localStorage.setItem("activePosition", JSON.stringify(activePosition));
        }
    }, [activePosition]);

    
    


    // Funktion um die letzte Position zu löschen
    const deleteLastPosition = () => {
        setAtlasPositionData((prevPositions) => {
            // Überprüfen, ob es Positionen zum Löschen gibt
            if (prevPositions.length === 0) {
                console.log("Keine Positionen zum Löschen vorhanden.");
                return prevPositions; // Keine Änderungen, wenn keine Positionen vorhanden sind
            }
    
            const indexToDelete = prevPositions.length - 1; 
            const lastPosition = prevPositions[indexToDelete]; 
    
            // Überprüfen, ob die aktive Position gelöscht werden soll
            if (activePosition && activePosition.warePositionsnummer === lastPosition.warePositionsnummer) {
                toast.error("Die aktive Position kann nicht gelöscht werden.", {
                    position: "bottom-right",
                });
                return prevPositions; // Keine Änderungen vornehmen, wenn die aktive Position gelöscht werden soll
            }
    
            // Entferne die letzte Position, da die aktive Position nicht gelöscht werden soll
            const newPositions = prevPositions.slice(0, prevPositions.length - 1); 
            return newPositions;
        });
    };







    //Atlas Warennummer GET

    const [wtnData, setWtnData] = useState([])

    useEffect(() => {
        const HandleWtnGet = async () => {

            const b_accesstoken = localStorage.getItem('b_accesstoken');
            const b_token = localStorage.getItem('b_token');

            const requestBody = {
                "query": "query getExportsWarenNummernByUserId($userid: String!,$filters: [FilterInput]) { getExportsWarenNummernByUserId(userid:$userid, filters: $filters) { code description besondereMabeinheit } }",
                    variables: {
                        userid: "FTAM",
                            filters: [
                                {
                                "field": "",
                                "value": ""
                                }
                            ]
                        }
                    }
    
            const res = await axios.post(`${Base.beo}GetData/warenummer`,
                JSON.stringify(requestBody), {
                headers: {
                    "Content-Type": "application/json",
                    "bearerToken": b_accesstoken,
                    "token": b_token
                }
            })
    
            if(res.status === 200){
                const data = await res.data
                setWtnData(data.getExportsWarenNummernByUserId)
            }
        }

        HandleWtnGet();
    
    }, [])
    


    // On cange refresh currentPosition
    useEffect(() => {
        const activePosition = JSON.parse(localStorage.getItem("position_number"));
        
        const fountPosition = atlasPositionData.find((position) => position.warePositionsnummer === activePosition);

        if (fountPosition){

            setActivePosition(fountPosition);
        }
    }, [ atlasPositionData]);


    return (
        <PositionDataContext.Provider value={{
            atlasPositionData,
            updateSpecificPosition,
            setActivePosition,
            activePosition,
            deleteLastPosition,

            //Data for bewilligung
            PosBewilligung,
            addBewilligunPos,
            setPosBewilligung,

            //Verpackung
            addVerpackungPos,
            PosVerpackung,
            setPosVerpackung,

            //Unterlage
            addUnterlagePos,
            PosUnterlage,

            //Atlas codes:
            wtnData
            }}>

            {loading ? <PageLoader/> : (
              <>
                  {children}
              </>
            )}
        </PositionDataContext.Provider>
    );
};

export const usePositionData = () => useContext(PositionDataContext);
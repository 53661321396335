import { useState } from 'react';
import './Accordion.css';

export const AccordionContainer = ({children, header}) => {
    return(
        <>
        {header ? <h4 className='bap_header_section_form_box'>{header}</h4> : null}
        <div className="bap_accordion_wrapper_main">
            {children}
        </div>
        </>
    )
}

export const AccordionTab = ({children, tabName="tabName"}) => {


    const [isOpen, setIsOpen] = useState(false);

    const handleOpen = () => {
        setIsOpen(!isOpen);
    }


    return(
        <>
        <div className="bap_accordion_tab">
            <div className="bap_accordion_tab_header_button" onClick={handleOpen}>
                <span>{tabName}</span>
                <span>+</span>
            </div>

            {isOpen === true ? (
                <>
                <div className="bap_accordion_content_wrapper">
                    {children}
                </div>
                </>
            ) : null}
        </div>
        </>
    )
}


export const AccordionGrid = ({children}) => {
    return(
        <>
        <div className="bap_accordion_display_grid">
            {children}
        </div>
        </>
    )
};


export const AccordionDisplayText = ({children, labelText ="labelText"}) => {
    return(
        <>
        <div className="bap_accordion_display_text">
            <span className='bap_accordion_display_text_label'>{labelText}</span>
            {children}
        </div>
        </>
    )
};
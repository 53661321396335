import { useRef, useState } from 'react';
import './Table.css';
import { FaArrowsAltV } from "react-icons/fa";
import { InlineInput } from '../../Input/TextField/Input';
import { Button} from '../../Button/Buttons';
import { useOutsideClick } from '../../../Utils/CloseHandler/useOutsideClick';

export const TableContainer = ({children}) => {
    return(
        <>
        <div className="bap_table_container">
            {children}
        </div>
        </>
    )
}


export const TableHeader = ({children}) => {
    return(
        <>
        <div className="bap_tabler_header">
            {children}
        </div>
        </>
    )
};


export const TableHeaderItem = ({ children, filterable, onFilterApply, handleDelete }) => {
    // Filter visibility state
    const [filter, setFilter] = useState(false);

    // Filter data state
    const [filterData, setFilterData] = useState({
        von: "",
        bis: "",
    });

    // Toggle filter view
    const handleFilterView = () => {
        setFilter(!filter);
    };

    // Handle outside click
    const ref = useRef(null);

    const handleCloseFilter = () => {
        setFilter(false);
    };

    useOutsideClick(ref, handleCloseFilter);

    // Handle onChange for date pickers
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle "Anwenden" button click
    const handleApplyFilter = () => {
        if (onFilterApply) {
            onFilterApply(filterData);
        }
        setFilter(false); // Close the filter view
    };


    //Handle "Löschen" button click
    const handleDeleteFilter = () => {
        handleDelete();
    };

    return (
        <>
            {filterable ? (
                <div className="bap_filter_wrapper" ref={ref}>
                    <div
                        className="bap_table_header_item bap_table_filter"
                        onClick={handleFilterView}
                    >
                        <span>{children}</span>
                        <FaArrowsAltV className="filterable_icon" />
                    </div>

                    {filter ? (
                        <div className="bap_filter_box_wrapper">
                            <InlineInput
                                onChange={handleDateChange}
                                label="von"
                                type="date"
                                name="von"
                                value={filterData.von}
                                placeholder="Filter..."
                            />
                            <InlineInput
                                onChange={handleDateChange}
                                label="bis"
                                type="date"
                                name="bis"
                                value={filterData.bis}
                                placeholder="Filter..."
                            />
                            <Button width="full" onClick={handleApplyFilter}>
                                Anwenden
                            </Button>
                            <Button width="full" onClick={handleDeleteFilter}>
                                Löschen
                            </Button>
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="bap_table_header_item">
                    <span>{children}</span>
                </div>
            )}
        </>
    );
};


export const TableBody = ({children}) => {
    return(
        <>
        <div className="bap_table_body">
            {children}
        </div>
        </>
    )
};

export const TableRow = ({children, onDoubleClick}) => {

    const handleNextStep = () => {
        onDoubleClick()
    }

    return(
        <>
        <div className="bap_table_body_row" onDoubleClick={handleNextStep}>
            {children}
        </div>
        </>
    )
}

export const TableItem = ({children}) => {
    return(
        <>
        <div className="bap_table_body_item">
            <span>
                {children}
            </span>
        </div>
        </>
    )
};





//This is the card for responsive view: 

export const CardContainer = ({children}) => {
    return(
        <>
        <div className="bap_card_container_wrapper">
            {children}
        </div>
        </>
    )
}


export const CardItem = ({lsNumber, CompanyName, No, onDoubleClick}) => {
    
    const handleOpenLs = () =>{
        onDoubleClick()
    }
    
    return(
        <>
        <div className="bap_card_item_wrapper" onDoubleClick={handleOpenLs}>
            <div className="bap_card_content_wrapper">
                <div className="bap_card_square_item">
                    <span>{No}</span>
                </div>
                <div className="bap_card_text_information_box">
                    <div className="bap_text_item_wrapper">
                        <span>{lsNumber}</span>
                        <p>{CompanyName}</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};
import { useState } from 'react';
import './Base.css';
import beologo from './beologo.png';
import { CiMenuBurger } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';



//Main Navigation slider

export const BaseWrapper = ({children}) => {

    const navigate = useNavigate();

    return(
        <>
        <div className="bap_page_wrapper">
            <div className="bap_page_header_with_logo">
                <img src={beologo} alt="" />
                <NavSectionButton>
                    <NavListItem goNavigate={() => navigate("/app/reybex/listitems")} >Arbeitsvorrat</NavListItem>
                    <NavListItem goNavigate={() => navigate("/app/beo/listmessages")}>Ausfuhrstatus</NavListItem>
                </NavSectionButton>
            </div>
            {children}
        </div>
        </>
    )
}


export const CreateAtlasWrapper = ({children}) => {
    return(
        <>
        <div className="bap_page_wrapper_create_atlas_message">
            {children}
        </div>
        </>
    )
}



export const ToolBar = ({children}) => {
    return(
        <>
        <div className="bap_toolbar">
            {children}
        </div>
        </>
    )
}


export const ListContentBase = ({children}) => {
    return(
        <>
        <div className="bap_list_content_base_wraper">
            {children}
        </div>
        </>
    )
};



//Main Nav Button
export const NavSectionButton = ({children}) => {

    const [navOpen, setNavOpen] = useState(false);

    return(
        <>
        <div className="bap_navbutton_wrapper" onClick={() => setNavOpen(!navOpen)}>
            <CiMenuBurger />
        </div>


        {navOpen ? (<>
            <div className="bap_nav_box_wrapper_main">
                <div className="bap_main_content_wrapper_menu_slider">
                    <div className="button_section_close_menu">
                        <span>Menü</span>
                        <div className="bap_close_btn_menu" onClick={() => setNavOpen(!navOpen)}>
                            <IoCloseOutline className='btn_close_icon'/>
                        </div>
                    </div>
                    <div className="bap_menu_content_wrapper_list_items">
                        {children}
                    </div>
                </div>
            </div>
        </>): null}

        </>
    )
}


export const NavListItem = ({children, goNavigate, active}) => {

    const HandleNavigation = () => {
        goNavigate();
    }

    return(
        <>
        <div className={active === true ? "bap_nav_list_item bap_nav_list_item_active" : "bap_nav_list_item"} onClick={HandleNavigation}>
            <span>{children}</span>
        </div>
        </>
    )
}






import { useEffect, useState } from 'react';
import './Container.css';

export const Container = ({children, size="large", Margin=""}) => {


    const[containerSize, setContainerSize] = useState();

    useEffect(() => {

        //Styles
        const small = {
            width: "50%", 
            height: "100%"
        }
        const medium = {
            width: "75%", 
            height: "100%"
        }

        const large = {
            width: "100%", 
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }


        const largeCenter = {
            width: "100%", 
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            marginTop: "25px"
        }


        if(size === "small"){
            setContainerSize(small);
        }else if(size === "medium"){
            setContainerSize(medium);
        }else if (size === "large"){
            setContainerSize(large);
        }else if(size === "largeCenter"){
            setContainerSize(largeCenter);
        }

    }, [size])



    return(
        <>
        <div className="bap_container_wrapper_main" style={containerSize}>
            {children}
        </div>
        </>
    )
};
import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"


//Table
import { useEffect, useState } from "react"

import { 
    TableContainer,
    TableHeader,
    TableBody,
    TableHeaderItem,
    TableItem,
    TableRow
 } from "../../../../Components/DataDisplay/Table/Table"

export const WiederEinfuhr = () => {

    const {wiederEinfuhrLand, addwiederEinfuhrLand, atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [newWiederEinfuhrLand, setNewWiederEinfuhrLand] = useState({
        wiedereinfuhrLand: ""
    });

    const handleSelectChange = (name, value) => {
        setNewWiederEinfuhrLand((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddUnterlageInHeader = (e) =>{
        e.preventDefault();
        addwiederEinfuhrLand(newWiederEinfuhrLand);
        setNewWiederEinfuhrLand({
            wiedereinfuhrLand: ""
        });
    }

    console.log(wiederEinfuhrLand);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Wiedereinfuhr" type="center">
                    <Container size="small">
                        <DropDownButton
                            name='Wiedereinfuhrland'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '138')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('wiedereinfuhrLand', item)}
                            dropname='wiedereinfuhrLand'
                            optionName={"Wiedereinfuhrland"}
                            codelist={"138"}
                        />
                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddUnterlageInHeader}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>
                    <TableHeader>
                        <TableHeaderItem>Nr</TableHeaderItem>
                        <TableHeaderItem>Wiedereinfuhrland</TableHeaderItem>
                    </TableHeader>

                    {wiederEinfuhrLand.wiedereinfuhr.length > 0 ? (
                        wiederEinfuhrLand.wiedereinfuhr.map((item, index) => (
                            <TableBody>
                                <TableRow key={index}>
                                    <TableItem>{index +1}</TableItem>
                                    <TableItem>{item.wiedereinfuhrLand}</TableItem>
                                </TableRow>
                            </TableBody>
                        ))
                    ) : (
                        <span>Keine Daten vorhanden</span>
                    )}
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
import React, { useEffect, useState } from 'react';
import { BaseWrapper,ToolBar } from '../../Components/Layout/BaseLayouts/Base/Base';
import { ButtonGroup, Button } from '../../Components/Button/Buttons';
import { CreateAtlasWrapper } from '../../Components/Layout/BaseLayouts/Base/Base';
import { TabHandler, TabItem } from '../../Components/Navigation/TabHandler/TabHandler';
import { ContentBase } from '../../Components/Layout/BaseLayouts/BaseContent/ContentBase';
import { useNavigate, useSearchParams } from 'react-router-dom';

//Sections
import { PosHeader } from './ATLAS-MESSAGE-TABS/POSITION/PosHeader';
import { PosBewilligung } from './ATLAS-MESSAGE-TABS/POSITION/PosBewilligung';
import { PosVerpackung } from './ATLAS-MESSAGE-TABS/POSITION/PosVerpackung';
import { PosUnterlage } from './ATLAS-MESSAGE-TABS/POSITION/PosUnterlage';

import { usePositionData } from '../../Utils/Contexts/PositionDataContext';
import { useFormData } from '../../Utils/Contexts/FormDataContext';

import { toast } from 'react-toastify';

//Mesage send
import { CreateAtlasMessage } from '../../Api/SendAtlasMessage/SendAtlasMessage';

function CreateAtlasMessagePosition() {

    const { deleteLastPosition, atlasPositionData, setActivePosition} = usePositionData();
    const  {
        headerData, 
        bewilligung, 
        suplyActors, 
        befoerderungsMittelAnDerGrenze, 
        zusätzlicheInformationen, 
        unterlageHeader,
        warenOrt,
        wiederEinfuhrLand,
        sonstigerVerweis,
        reybexData
    } = useFormData();

    useEffect(() => {
        const handleBeforeUnlaod = (e) =>{
            const message = "Möchten Sie die Seite wirklich verlassen?";
            e.returnValue = message;
            return message;
        }

        window.addEventListener('beforeunload', handleBeforeUnlaod);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnlaod);
        }
    })

    const [searchParams] = useSearchParams();
    const step = searchParams.get('step');

    let positionData = null;

    switch(step) {
        case 'articleposition':
            positionData = <PosHeader />;
            break; 
        case 'bewilligung':
            positionData = <PosBewilligung />;
            break; 
        case 'verpackung':
            positionData = <PosVerpackung />;
            break; 
        case 'unterlage':
            positionData = <PosUnterlage />;
            break; 
        default: 
            positionData = <PosHeader />;
            break;
    } 


    //Handle Query change
    const navigate = useNavigate();


    //cancle the atlas request
    const CancleMessageCreation = () => {
        navigate("/app/reybex/listitems");
    }


    //HandleSubmit
    const [status, setStatus] = useState(null); // Status für Toast-Meldungen

    const handleStatusUpdate = (statusUpdate) => {
        setStatus(statusUpdate);
    };

    const HandleAtlasMessage = async () => {
        try {
            // Asynchrone Funktion aufrufen und Status-Update handhaben
            await CreateAtlasMessage(
                atlasPositionData,
                headerData,
                bewilligung,
                suplyActors,
                befoerderungsMittelAnDerGrenze,
                zusätzlicheInformationen,
                unterlageHeader,
                warenOrt,
                reybexData,
                wiederEinfuhrLand,
                sonstigerVerweis,
                handleStatusUpdate // Callback zum Aktualisieren des Status
            );
    
            // Überprüfen, ob der Status aktualisiert wurde
            if (status) {
                toast.success(status.message, {
                    position: "bottom-right",
                });
                navigate("/app/beo/listmessages");
            }
        } catch (error) {
            console.error("Fehler beim Senden der Nachricht:", error);
            toast.error("Ein Fehler ist aufgetreten. Bitte erneut versuchen.", {
                position: "top-right",
            });
        }
    };
    


    const handleTabSwitch = (step) => {
        const savedPosition = localStorage.getItem("activePosition");
        if (savedPosition) {
            setActivePosition(JSON.parse(savedPosition));
        }
        navigate(`?step=${step}`);
    };
    

    return ( 
        <>
            <BaseWrapper>
                <ToolBar>
                    {/*Here the Toolbar buttons will be displayed*/}
                    <ButtonGroup>
                        <Button onClick={() => navigate("/app/create/atlasmessage?step=headerdata")}>Kopfdaten</Button>
                        <Button onClick={() => CancleMessageCreation()}>Abbrechen</Button>
                        <Button onClick={() => deleteLastPosition()}>Letzte Position löschen</Button>
                        <Button onClick={() => HandleAtlasMessage()}>Anmeldung Senden</Button>
                    </ButtonGroup>
                </ToolBar>


                <CreateAtlasWrapper>
                    <TabHandler>
                        <TabItem goTo={() => handleTabSwitch("articleposition")} active={step === "articleposition"}>Warenposition</TabItem>
                        <TabItem goTo={() => handleTabSwitch("bewilligung")} active={step === "bewilligung"}>Bewilligung</TabItem>
                        <TabItem goTo={() => handleTabSwitch("verpackung")} active={step === "verpackung"}>Verpackung</TabItem>
                        <TabItem goTo={() => handleTabSwitch("unterlage")} active={step === "unterlage"}>Unterlage</TabItem>
                    </TabHandler>



                    <ContentBase>
                        {positionData}
                    </ContentBase>

                </CreateAtlasWrapper>
            
            </BaseWrapper>
        </>
     );
}

export default CreateAtlasMessagePosition;
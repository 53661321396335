import './PageLoader.css';

export const PageLoader = () => {

    return(
        <>
        <div className="bap_page_loading_wrapper">
            <div className="bap_page_loader_container">
                <div className="bap_page_loader_background">{/*Macht den Hintergrund dunkler.*/}</div>
                <div className="bap_loading_spinner">
                    {/*Nur für den Ladespinner*/}
                </div>
            </div>
        </div>
        </>
    )

}
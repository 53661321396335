import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { Container } from "../../../../Components/Layout/Container/Container"
import { 
    TableContainer,
    TableHeader,
    TableHeaderItem,
    TableBody,
    TableRow,
    TableItem
 } from "../../../../Components/DataDisplay/Table/Table"

import { Input } from "../../../../Components/Input/TextField/Input"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"
import { usePositionData } from "../../../../Utils/Contexts/PositionDataContext"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { useEffect, useState } from "react"
import { AccordionGrid } from "../../../../Components/DataDisplay/AccordionTab/Accordion"

export const PosUnterlage = () => {

    //Get Data
    const {addUnterlagePos, PosUnterlage, activePosition} = usePositionData();
    const {atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const[newPosUnterlage, setNewPosUnterlage] = useState({
        art: "",
        qualifikator: "",
        referenznummer: "",
        zeilenPositionsnummer: "",
        zusatzlicheAngaben:"",
        detail: "",
        namederausstellendenBehorde: "",
        datumderAusstellung: "",
        gultigkeitsdatum: "",
        mabeinheit: "",
        erganzendeMabeinheit: "",
        menge: "",
        wahrung: "",
        betrag: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPosUnterlage((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewPosUnterlage((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddUnterlage = (e) =>{
        e.preventDefault();
        addUnterlagePos(activePosition.warePositionsnummer, newPosUnterlage);

        console.log(activePosition.warePositionsnummer, newPosUnterlage);
        setNewPosUnterlage({ 
            art: "",
            qualifikator: "",
            referenznummer: "",
            zeilenPositionsnummer: "",
            zusatzlicheAngaben:"",
            detail: "",
            namederausstellendenBehorde: "",
            datumderAusstellung: "",
            gultigkeitsdatum: "",
            mabeinheit: "",
            erganzendeMabeinheit: "",
            menge: "",
            wahrung: "",
            betrag: "",
        });
    }


    return(
        <>
        <FormWrapper>
            <ColumnGrid>
                <FormBox header="Verpackung" type="center">
                    <Container size="large">
                            <AccordionGrid>
                                <Container size="largeCenter">
                                    <DropDownButton
                                        name='Art'
                                        placeholder='Bitte auswählen...'
                                        items={atlasCodeList
                                            ? atlasCodeList.find(item => item.type === '121')?.options || []
                                            : []
                                        }
                                        onSelect={(item) => handleSelectChange('art', item)}
                                        dropname='art'
                                    />
                                    <Input
                                        label="Qualifikator"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="qualifikator"
                                        value={PosUnterlage.qualifikator}
                                    />
                                    <Input
                                        label="Referenznummer"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="referenznummer"
                                        value={PosUnterlage.referenznummer}
                                    />
                                    <Input
                                        label="Zeilen/Positionsnummer"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="zeilenPositionsnummer"
                                        value={PosUnterlage.zeilenPositionsnummer}
                                    />
                                    <Input
                                        label="Zusätzliche Angaben"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="zusatzlicheAngaben"
                                        value={PosUnterlage.zusatzlicheAngaben}
                                    />
                                    <Input
                                        label="Detail"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="detail"
                                        value={PosUnterlage.detail}
                                    />
                                    <Input
                                        label="ausstellende Behörde"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="namederausstellendenBehorde"
                                        value={PosUnterlage.namederausstellendenBehorde}
                                    />                        
                                    <ButtonGroup>
                                        <Button onClick={handleAddUnterlage}>Hinzufügen</Button>
                                    </ButtonGroup>
                                </Container>

                                <Container size="largeCenter">
                                    <Input
                                        label="Datum der Ausstellung"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="datumderAusstellung"
                                        value={PosUnterlage.datumderAusstellung}
                                        type="date"
                                    />
                                    <Input
                                        label="Gültigkeitsdatum"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="gultigkeitsdatum"
                                        value={PosUnterlage.gultigkeitsdatum}
                                        type="date"
                                    />
                                    <DropDownButton
                                        name='Maßeinheit'
                                        placeholder='Bitte auswählen...'
                                        items={atlasCodeList
                                            ? atlasCodeList.find(item => item.type === '140')?.options || []
                                            : []
                                        }
                                        onSelect={(item) => handleSelectChange('mabeinheit', item)}
                                        dropname='mabeinheit'
                                    />
                                    <DropDownButton
                                        name='Ergänzende Maßeinheit'
                                        placeholder='Bitte auswählen...'
                                        items={atlasCodeList
                                            ? atlasCodeList.find(item => item.type === '160')?.options || []
                                            : []
                                        }
                                        onSelect={(item) => handleSelectChange('art', item)}
                                        dropname='art'
                                    />
                                    <Input
                                        label="Menge"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="menge"
                                        value={PosUnterlage.menge}
                                    />                                    
                                    <DropDownButton
                                        name='Währung'
                                        placeholder='Bitte auswählen...'
                                        items={atlasCodeList
                                            ? atlasCodeList.find(item => item.type === '114')?.options || []
                                            : []
                                        }
                                        onSelect={(item) => handleSelectChange('wahrung', item)}
                                        dropname='wahrung'
                                    />
                                    <Input
                                        label="Betrag"
                                        isDisabled={false}
                                        onChange={handleInputChange}
                                        name="betrag"
                                        value={PosUnterlage.betrag}
                                    />  
                                </Container>
                            </AccordionGrid>
                    </Container>
                </FormBox>

                <TableContainer>
                        <TableHeader>
                            <TableHeaderItem>
                                Nr.
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Art der Unterlage
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Qualifikator
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Referenznummer
                            </TableHeaderItem>
                            <TableHeaderItem>
                                Optionen
                            </TableHeaderItem>
                        </TableHeader>
                        {/* Table Body */}
                        {activePosition?.unterlage.map((item, index) => (
                            <>
                                <TableRow>
                                    <TableItem>
                                        {index + 1}
                                    </TableItem>
                                    <TableItem>
                                        {item.art}
                                    </TableItem>
                                    <TableItem>
                                        {item.qualifikator}
                                    </TableItem>
                                    <TableItem>
                                        {item.referenznummer}
                                    </TableItem>
                                    <TableItem>
                                        Löschen
                                    </TableItem>
                                </TableRow>
                            </>))}
                        <TableBody>
                        </TableBody>
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
import React from 'react';

function FallBackPage() {
    return ( 
        <>
        <h3>FallBackPage</h3>
        </>
     );
}

export default FallBackPage;
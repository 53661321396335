import { useEffect } from 'react';

// Utility to detect clicks outside of a referenced element
export const useOutsideClick = (ref, handler) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the click happened outside the referenced element, trigger the handler
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    };

    // Attach the click event listener to the entire window
    window.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener when the component is unmounted or ref/handler changes
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler]);
};
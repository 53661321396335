import React, { useEffect, useState } from 'react';
import { BaseWrapper, ToolBar, ListContentBase } from '../../Components/Layout/BaseLayouts/Base/Base';
import { ButtonGroup } from '../../Components/Button/Buttons';
import axios from 'axios';
import Base from '../../Api/Base.json';
import { useFormData } from '../../Utils/Contexts/FormDataContext';
import { SearchBar } from '../../Components/FilterOptions/SearchBar/SearchBar';

// Importing the filter function
import { FilterLsForExport } from '../../Utils/FilterBaseReyBex/FilterCountries';

// Table
import { 
    TableContainer, 
    TableHeader, 
    TableHeaderItem, 
    TableBody, 
    TableItem, 
    TableRow,
    CardContainer,
    CardItem
} from '../../Components/DataDisplay/Table/Table';

import { PageLoader } from '../../Components/LoadingAnimation/PageLoader/PageLoader';

// Window size
import useWindowSize from '../../Utils/Evenlistner/GetWindowSize';
import { useNavigate } from 'react-router-dom';

function ReyBexDataListIems() {
    const { width } = useWindowSize();
    const { setLsNumber, filteresLs} = useFormData();

    const [reyBexLs, setReyBexLs] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [loading, setLoading] = useState(true);

    const [filters, setFilters] = useState({
        von: "",
        bis: "",
    });

    const navigate = useNavigate();

    useEffect(() => {
        const r_token = localStorage.getItem('r_token');
        const GetReyBexLs = async () => {
            setLoading(true);
            if (r_token) {
                try {
                    // API-Anfragen
                    const reyls = await axios.get(`${Base.reybex}domains/salesHead?docType.name=eq(Lieferschein)`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${r_token}`,
                        }
                    });
    
                    const reyTags = await axios.get(`${Base.reybex}salesHead?docType.name=eq(Lieferschein)`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Basic ${r_token}`,
                        }
                    });
    
                    // Wenn beide Anfragen erfolgreich sind
                    if (reyls.status === 200 && reyTags.status === 200) {
                        const ReyBexApiData = await reyls.data; // Lieferscheine
                        const ReyBexTagsApi = await reyTags.data; // Tags
    
                        // Gruppiere die Tags nach salesHead_hid
                        const groupedTags = ReyBexTagsApi.data.reduce((acc, item) => {
                            const salesHeadId = item.salesHead_hid;
    
                            if (!acc[salesHeadId]) {
                                acc[salesHeadId] = [];
                            }
    
                            const tagsForItem = (item.tags || []).map(tag => ({
                                id: tag.id,
                                name: tag.translatedName,
                                color: tag.color
                            }));
    
                            acc[salesHeadId] = acc[salesHeadId].concat(tagsForItem);
    
                            return acc;
                        }, {});
    
                        // Verknüpfe die Tags mit den Lieferscheinen
                        const enrichedData = ReyBexApiData.map(item => ({
                            ...item,
                            foundTags: groupedTags[item.id] || [] 
                        }));
    
                        console.log("Enriched ReyBexApiData:", enrichedData);
    
                        // Filtere nach "Ausfuhr"
                        const filteredByTag = enrichedData.filter(item => {
                            return !item.foundTags.some(tag => tag.name === "Ausfuhr");
                        });
    
                        console.log("Filtered Data (no 'Ausfuhr'):", filteredByTag);

                        const filteredData = await FilterLsForExport({ ls_data: filteredByTag });
                        if (filteredData) {
                            const sortData = filteredData.sort((a, b) => new Date(b.docDate) - new Date(a.docDate));
                            setReyBexLs(sortData);
                        }
                    }
                } catch (err) {
                    console.error("Fehler beim Abrufen der Daten:", err);
                }
            }
            setLoading(false);
        };
    
        GetReyBexLs();
    }, []);
    
    


    useEffect(() => {
        const determineDataToDisplay = () => {
            console.log("Filters: ", filters);
            // 1. Keine Filter: Standardanzeige von allen Daten
            if (!filters.von && !filters.bis && !filteresLs) {
                setDataToDisplay(reyBexLs);
                return;
            }
    
            // 2. `filteresLs` aktiv: Zeige `filteresLs`
            if (filteresLs.length > 0) {
                console.log(filteresLs)
                setDataToDisplay(filteresLs);
            }
    
            // 3. Datum-Filter aktiv: Berechne gefilterte Date
            if (filters.von || filters.bis) {
                const fromDate = filters.von ? new Date(filters.von) : null;
                const toDate = filters.bis ? new Date(filters.bis) : null;
    
                const filteredReyBexLs = reyBexLs.filter((item) => {
                    const itemDate = new Date(item.docDate);
                    return (
                        (!fromDate || itemDate >= fromDate) &&
                        (!toDate || itemDate <= toDate)
                    );
                });
                setDataToDisplay(filteredReyBexLs);
            }
        };
    
        determineDataToDisplay();
        // Set Filters darf nicht in eine Endlosschleife führen
    }, [filters, reyBexLs, filteresLs]); // Nur relevante Abhängigkeiten
    
    


    // Apply filters
    const applyFilter = (filterData) => {
       
        setFilters(filterData);
    };

    // Select LS
    const selectReyBexLs = (ls_number, ls_id) => {
        setLsNumber((prevData) => ({
            ...prevData,
            ls_id: ls_id,
            ls_number: ls_number
        }));
        navigate(`/app/create/atlasmessage?step=headerdata`);
    };

    // Format date for display
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    };


    //Const Delete Filter: 
    const handleDelete = () => {
        setFilters({
            von: "",
            bis: "",
        });
    };

    return (
        <>
            {loading ? (
                <PageLoader />
            ) : (
                <BaseWrapper>
                    <ToolBar>
                        <ButtonGroup type="end">
                            <SearchBar items={reyBexLs} />
                        </ButtonGroup>
                    </ToolBar>
                    <ListContentBase>
                        {width > 1150 ? (
                            <TableContainer>
                                <TableHeader>
                                    <TableHeaderItem>Nr.</TableHeaderItem>
                                    <TableHeaderItem>Lieferschein Nr.</TableHeaderItem>
                                    <TableHeaderItem filterable onFilterApply={applyFilter} handleDelete={handleDelete}>Erstellt am.</TableHeaderItem>
                                    <TableHeaderItem>Kunde</TableHeaderItem>
                                    <TableHeaderItem>Bestimmungsland</TableHeaderItem>
                                    <TableHeaderItem>Positionen</TableHeaderItem>
                                    <TableHeaderItem>Rechnungsbetrag</TableHeaderItem>
                                </TableHeader>
                                <TableBody>
                                    {dataToDisplay.map((item, index) => (
                                        <TableRow key={index} onDoubleClick={() => selectReyBexLs(item.docNumber, item.id)}>
                                            <TableItem>{index + 1}</TableItem>
                                            <TableItem>{item.docNumber}</TableItem>
                                            <TableItem>{formatDate(item.docDate)}</TableItem>
                                            <TableItem>{item.customer.name}</TableItem>
                                            {item.receiverCountry ? (
                                                <TableItem>{`${item.receiverCountry.code} - ${item.receiverCountry.name}` || ""}</TableItem>
                                            ) : (
                                                <TableItem>{`${item.customerCountry.code} - ${item.customerCountry.name}` || ""}</TableItem>
                                            )}
                                            <TableItem>{item.items.length}</TableItem>
                                            <TableItem>{item.grossAmount.toFixed(2) + "€"}</TableItem>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </TableContainer>
                        ) : (
                            <CardContainer>
                                {dataToDisplay.map((item, index) => (
                                    <CardItem
                                        No={index + 1}
                                        lsNumber={item.docNumber}
                                        CompanyName={item.receiverAddressline1}
                                        onDoubleClick={() => selectReyBexLs(item.docNumber, item.id)}
                                        key={index}
                                    />
                                ))}
                            </CardContainer>
                        )}
                    </ListContentBase>
                </BaseWrapper>
            )}
        </>
    );
}

export default ReyBexDataListIems;

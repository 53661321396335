import React from 'react';
import './Input.css';

export const Input = ({
  type = "text",
  value,
  defaultValue,
  maxLength,
  minLength,
  onBlur,
  onChange,
  onFocus,
  name = "",
  label = "label",
  isDisabled=false
}) => {

  const handleChange = (e) => {
    // Ruft die onChange-Funktion der übergeordneten Komponente auf
    onChange(e, name);  // Übergib den Event und den Name an die übergeordnete Komponente
  };

  return (
    <>
      <div className="bap_input_wrapper">
        <span>{label}</span>
        <input
          style={isDisabled ? { backgroundColor: "var(--bap_input_disabled)" } : {}}
          type={type}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          minLength={minLength}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange} // Verwende die handleChange-Funktion lokal
          name={name}
          disabled={isDisabled}
        />
      </div>
    </>
  );
};



export const InlineInput = ({
  type = "text",
  value,
  defaultValue,
  maxLength,
  minLength,
  onBlur,
  onChange,
  onFocus,
  name = "",
  label = "label",
}) => {

  const handleChange = (e) => {
    // Ruft die onChange-Funktion der übergeordneten Komponente auf
    onChange(e, name);  // Übergib den Event und den Name an die übergeordnete Komponente
  };

  return (
    <>
      <div className="bap_inline_input_wrapper">
        <label>{label}</label>
        <input
          type={type}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          minLength={minLength}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange} // Verwende die handleChange-Funktion lokal
          name={name}
        />
      </div>
    </>
  );
};




export const TextArea = ({
  type = "text",
  value,
  defaultValue,
  maxLength,
  minLength,
  onBlur,
  onChange,
  onFocus,
  name = "",
  label = "label",
}) => {

  const handleChange = (e) => {
    onChange(e, name);
  };

  return(
    <div className="bap_inline_input_wrapper">
      <label>{label}</label>
        <textarea
          type={type}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxLength}
          minLength={minLength}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleChange} // Verwende die handleChange-Funktion lokal
          name={name}
        />
    </div>
  )
}
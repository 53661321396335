import { FormWrapper } from "../../../../Components/Layout/Forms/FormWrapper/FormWrapper"
import { FormBox } from "../../../../Components/Layout/Forms/FormBox/FormBox"
import { ColumnGrid } from "../../../../Components/Layout/Grids/ColumGrid/ColumnGrid"
import { Container } from "../../../../Components/Layout/Container/Container"
import { Input } from "../../../../Components/Input/TextField/Input"
import { useFormData } from "../../../../Utils/Contexts/FormDataContext"
import { DropDownButton } from "../../../../Components/Input/DropDown/Dropdown"
import { Button, ButtonGroup } from "../../../../Components/Button/Buttons"


//Table
import { TableContainer } from "../../../../Components/DataDisplay/Table/Table"
import { useEffect, useState } from "react"

export const SonstigerVerweisHeader = () => {

    const {sonstigerVerweis, addSonstigerVerweis, atlasData} = useFormData();

    const [atlasCodeList, setAtlasCodeList] = useState([]);


    useEffect(() => {
        if(atlasData){
            setAtlasCodeList(atlasData)
        }
    }, [atlasData]);


    const [newSonstigerVerweis, setNewSonstigerVerweis] = useState({
        art: "",
        qualifikator: "",
        referenznummer: ""
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSonstigerVerweis((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setNewSonstigerVerweis((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAddUnterlageInHeader = (e) =>{
        e.preventDefault();
        addSonstigerVerweis(newSonstigerVerweis);
        setNewSonstigerVerweis({
            art: "",
            qualifikator: "",
            referenznummer: "",
        });
    }

    console.log(sonstigerVerweis);

    return(
        <>
        <FormWrapper action="submit">
            <ColumnGrid>
                <FormBox header="Sonstiger Verweis" type="center">
                    <Container size="small">
                        <DropDownButton
                            name='Art'
                            placeholder='Bitte auswählen...'
                            items={atlasCodeList
                                ? atlasCodeList.find(item => item.type === '156')?.options || []
                                : []
                            }
                            onSelect={(item) => handleSelectChange('art', item)}
                            dropname='art'
                            optionName={"Sonstiger Verweis"}
                            codelist={"156"}
                        />
                        
                        <Input
                            label="Quialifikator"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="qualifikator"
                            value={newSonstigerVerweis.qualifikator}
                        />
                        <Input
                            label="Referenznummer"
                            isDisabled={false}
                            onChange={handleInputChange}
                            name="referenznummer"
                            value={newSonstigerVerweis.referenznummer}
                        />
                        <ButtonGroup>
                            <Button type="submit" onClick={handleAddUnterlageInHeader}>
                                Hinzufügen
                            </Button>
                        </ButtonGroup>

                    </Container>
                </FormBox>

                <TableContainer>
                    {sonstigerVerweis.sonstigerVerweis.length > 0 ? (
                        sonstigerVerweis.sonstigerVerweis.map((item, index) => (
                            <div key={index}>
                                <span>{item.art}</span>
                                <span>{item.qualifikator}</span>
                                <span>{item.identifikationsnummer}</span>
                            </div>
                        ))
                    ) : (
                        <span>Keine Daten vorhanden</span>
                    )}
                </TableContainer>
            </ColumnGrid>
        </FormWrapper>
        </>
    )
}
import React from 'react';
import './AtlasPluginSetUp.css';
import { useSearchParams } from 'react-router-dom';

//Import Steps
import ReyBexSetup from './ReyBex/ReyBexSetUp';
import BeoSetUp from './Beo/BeoSetUp';

function AtlasPluginSetUp() {
    const [searchParams] = useSearchParams();
    const step = searchParams.get('step');

    let content = null;

    switch(step) {
        case 'reybex':
            content = <ReyBexSetup />;
            break; 
        case 'atlas':
            content = <BeoSetUp />;
            break; 
        default: 
            content = <ReyBexSetup />;
            break;
    }
    return ( 
        <>
        <div className="bap_atlas_setup">
            {content}
        </div>
        </>
     );
}

export default AtlasPluginSetUp;